import { useEffect, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import getArticleType, { getArticleTypeName } from '../../helpers/article/getArticleType'
import useNavigatedCallback from '../useNavigatedCallback'
/**
 * handles initial prev-next article params
 * articleType - parsed articleTypeName for given article
 * isPrevNext - is the given article a prev-next article
 * articlePage - current page of prev-next article
 * isFirstPageView - has the user just landed on this page or has he navigated
 * internalArticleType - internal name of article type, eg "rtl-hr-article-type-prev-next"
 */
const usePrevNextInitialData = ({ article, appName = 'all', typeNameMap }) => {
    const router = useRouter()

    const { query = {} } = router
    const { pageNumber } = query

    const internalArticleType = getArticleType(article)
    const articleType = getArticleTypeName(internalArticleType, appName, typeNameMap)

    const isPrevNext = internalArticleType?.match(/article-type-prev-next/) && !router?.query?.fullArticle
    const articlePage = parseInt(pageNumber, 10) || 1
    const [isFirstPageview, setIsFirstPageview] = useState(true)

    const resetCount = useNavigatedCallback(() => {
        setIsFirstPageview(false)
    })

    const lastArticleIdRef = useRef(null)
    useEffect(() => {
        if (!article) {
            return
        }
        if (article?.id === lastArticleIdRef.current) {
            return
        }
        setIsFirstPageview(true)
        resetCount()
        lastArticleIdRef.current = article.id
    }, [article])

    return {
        articleType,
        internalArticleType,
        isPrevNext,
        articlePage,
        isFirstPageview
    }
}
export default usePrevNextInitialData
