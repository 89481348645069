/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useUIDSeed } from 'react-uid'

import { Column } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'

import { articleCardContentMarkerVariants } from '@hmn/rtl-net-ui/components/Article/components'
import ArticleCard from '@hmn/rtl-net-ui/components/Article/components/Card/Card.component'
import CardsBlock from '@hmn/rtl-net-ui/components/CardsBlock/CardsBlock.component'

const articleCardContentMarkerVariant = type => {
    switch (type.toUpperCase()) {
        case 'ARTICLE':
            return articleCardContentMarkerVariants.ARTICLE
        case 'GALLERY':
            return articleCardContentMarkerVariants.GALLERY
        case 'VIDEO':
            return articleCardContentMarkerVariants.VIDEO
        default:
            return articleCardContentMarkerVariants.NONE
    }
}

const gtmData = {
    eventCategory: 'Clanak',
    eventAction: 'Below Izdvojeno',
    eventLabel: 'Main'
}

function SelectedArticlesBox({ title = 'Izdvojeno', excludeId = undefined }) {
    const uid = useUIDSeed()

    const [data, setData] = useState([])

    useEffect(() => {
        const searchParams = new URLSearchParams([['limit', 5]])

        if (excludeId) {
            searchParams.append('exclude', excludeId)
        }

        fetch(`/api/selected-articles?${searchParams.toString()}`).then(async response => {
            if (!response.ok) {
                // eslint-disable-next-line no-console
                console.error(response.body)
                return
            }

            const entities = await response.json()

            setData(entities)
        })
    }, [excludeId])

    if (!data?.length) {
        return null
    }

    return (
        <Column variant="article.izdvojeno" id="izdvojeno">
            <Media greaterThan="sm" scaleDown>
                <CardsBlock
                    title={title}
                    titleSizeVariant="1x1"
                    areaXs={`"a" "b" "c" "d" "e" "f"`}
                    areaMd={`"a b c" "a b d" "a b e"`}
                    columnsXs="1fr"
                    columnsMd="1fr 1fr 1fr"
                    count={5}
                    config={[
                        {
                            variant: 'ALPHA',
                            size: '1x5',
                            image: 'CUSTOM_SQUARE'
                        },
                        {
                            variant: 'ALPHA',
                            size: '1x5',
                            image: 'CUSTOM_SQUARE'
                        },
                        {
                            variant: 'ALPHA',
                            size: '1x4',
                            image: 'CUSTOM_WIDE_SCREEN',
                            imageHeight: 200,
                            imageWidth: 355
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE'
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE'
                        }
                    ]}>
                    {data?.map((item, index) => (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                            gtmData={gtmData}
                        />
                    ))}
                </CardsBlock>
            </Media>
            <Media lessThan="md" scaleDown>
                <CardsBlock
                    title={title}
                    titleSizeVariant="1x1"
                    areaXs={`"a" "b" "c" "d" "e" "f"`}
                    areaMd={`"a b c" "a b d" "a b e"`}
                    columnsXs="1fr"
                    columnsMd="1fr 1fr 1fr"
                    count={5}
                    config={[
                        {
                            variant: 'ALPHA',
                            size: '1x4',
                            image: 'CUSTOM_WIDE_SCREEN',
                            imageHeight: 200,
                            imageWidth: 355
                        },
                        {
                            variant: 'ALPHA',
                            size: '1x4',
                            image: 'CUSTOM_WIDE_SCREEN',
                            imageHeight: 200,
                            imageWidth: 355
                        },
                        {
                            variant: 'ALPHA',
                            size: '1x4',
                            image: 'CUSTOM_WIDE_SCREEN',
                            imageHeight: 200,
                            imageWidth: 355
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE'
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE'
                        }
                    ]}>
                    {data?.map((item, index) => (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                            gtmData={gtmData}
                        />
                    ))}
                </CardsBlock>
            </Media>
        </Column>
    )
}

export default SelectedArticlesBox
