import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { useIsTransitioning } from '../../context/clientNavigation'
import { useRouteChangeComplete } from '../../hooks/useRouteChange'

export const upScoreArticleToItem = (a, widgetId) => ({
    title: a.title,
    subtitle: a.portal,
    lead: a.description,
    image: `https://img2.thecrossnet.io/images/${a.article_id}.jpeg?format=webp`,
    href: a.url,
    id: a.article_id,
    isUpscoreArticle: true,
    widgetId
})

const fetchWidgetData = id =>
    fetch?.(`https://thecrossnet.io/c/${id}`, {
        method: 'GET'
    }).then(async r => {
        if (r.state !== 200) {
            const result = await r.json()
            return { ...result, id }
        }
        return null
    })
export const sendThecrossnet = (endpoint, data) => {
    if (!endpoint || !data) {
        return
    }
    fetch?.(`https://thecrossnet.io/${endpoint}`, {
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify(data)
    })
}
export const sendThecrossnetClick = item => sendThecrossnet('cl', { wh: item.widgetId, ah: item.id })
export const sendThecrossnetView = item => sendThecrossnet('av', { wh: item.widgetId, ah: item.id })

const windowKeyName = '__exchangeArticles'
const setArticleIdsAsViewed = articleIds => articleIds.forEach(id => window[windowKeyName].set(id, true))
const removeArticleIdsAsViewed = articleIds => articleIds.forEach(id => window[windowKeyName].delete(id))
const checkArticleIdViewed = id => !!window[windowKeyName]?.get?.(id)

// @NOTE: this is fine since it is only ran on client
const randomSort = () => Math.random() > 0.5
const takeRandomFilteredArticlesFromStream = (articleList, takeCount) => {
    const filtered = articleList?.filter(item => !checkArticleIdViewed(item.article_id)) || []
    const itemsToTake = filtered.length < takeCount ? filtered.length : takeCount
    const selected = filtered.sort(randomSort).slice(0, itemsToTake)
    return selected
}

const processSingleWidgetArticleList = (
    { Pinned, MaxPinned, Articles, MaxItems, Campaign, MaxCampaign, Internal, id },
    takeExtra
) => {
    const articlesToDisplay = []
    // Campaign Articles
    if (Campaign && Campaign.length > 0 && MaxCampaign > 0) {
        const selectedCampaignArticles = takeRandomFilteredArticlesFromStream(
            Campaign,
            MaxCampaign - articlesToDisplay.length
        )
        articlesToDisplay.push(...selectedCampaignArticles)
    }

    // Pinned Articles
    if (Pinned && Pinned.length > 0 && MaxPinned > 0) {
        const spaceLeft = MaxItems - articlesToDisplay.length
        const pinnedToLoad = spaceLeft > MaxPinned ? MaxPinned : spaceLeft
        const selectedPinnedArticles = takeRandomFilteredArticlesFromStream(Pinned, pinnedToLoad)
        articlesToDisplay.push(...selectedPinnedArticles)
    }

    // Exchange-articles
    const selectedExchangeArticles = takeRandomFilteredArticlesFromStream(
        Articles,
        MaxItems - articlesToDisplay.length + takeExtra
    )
    articlesToDisplay.push(...selectedExchangeArticles)

    // Internal
    const internalToTake = MaxItems - articlesToDisplay.length + takeExtra
    if (internalToTake > 0) {
        const selectedInternalArticles = takeRandomFilteredArticlesFromStream(Internal, internalToTake)
        articlesToDisplay.push(...selectedInternalArticles)
    }
    return { articles: articlesToDisplay, id, MaxItems }
}

const useWidgetsData = ({ enabled, widgetIds }) => {
    const [widgetsData, setWidgetData] = useState([])

    const mounted = useRef(false)
    const loaded = useRef(false)
    useEffect(() => {
        if (mounted.current || loaded.current || !enabled || !widgetIds || widgetIds.length === 0) {
            return
        }

        mounted.current = true
        Promise.allSettled(widgetIds.map(fetchWidgetData)).then(responses => {
            if (loaded.current) {
                return
            }
            loaded.current = true
            setWidgetData(responses.filter(response => response.status === 'fulfilled').map(response => response.value))
        })
    }, [enabled, widgetIds])
    return { widgetsData }
}

const useParsedWidgets = ({ widgetsData }) => {
    const router = useRouter()

    useEffect(() => {
        if (!window[windowKeyName]) {
            window[windowKeyName] = new Map()
        }

        const handleRouteChangeStart = () => {
            window[windowKeyName].clear()
        }
        router.events.on('routeChangeStart', handleRouteChangeStart)
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart)
        }
    }, [])

    const [parsedWidgets, setParsedWidgets] = useState([])
    useEffect(() => {
        setParsedWidgets(widgetsData.map(widgetData => processSingleWidgetArticleList(widgetData, 3)))
    }, [widgetsData])
    return { parsedWidgets }
}

export const useThecrossnetData = ({ widgetIds, enabled = true, count = widgetIds.length || 0 }) => {
    const { widgetsData } = useWidgetsData({ enabled, widgetIds })
    const { parsedWidgets } = useParsedWidgets({ widgetsData })

    const [isTransitioning] = useIsTransitioning()
    const populatedThisNavRef = useRef(false)
    const [data, setData] = useState([])

    const usedIdsRef = useRef([])

    useEffect(() => {
        const destroy = () => {
            removeArticleIdsAsViewed(usedIdsRef.current)
        }
        if (populatedThisNavRef.current || isTransitioning || !enabled) {
            return destroy
        }
        const finalArticles = []
        const widgetsWithArticles = parsedWidgets.filter(w => w?.articles?.length > 0)
        if (widgetsWithArticles.length === 0) {
            return destroy
        }
        const populateArticleList = () =>
            widgetsWithArticles.forEach(({ articles, id, MaxItems }) => {
                const leftToAdd = count - finalArticles.length
                const takeArticles = Math.min(leftToAdd, MaxItems)
                const parsedArticlesToAdd = articles
                    .filter(a => usedIdsRef.current.indexOf(a.article_id) === -1)
                    .slice(0, takeArticles)
                    .map(article => upScoreArticleToItem(article, id))
                finalArticles.push(...parsedArticlesToAdd)
                usedIdsRef.current.push(...parsedArticlesToAdd.map(a => a.id))
            })
        populateArticleList()

        setData(finalArticles)
        populatedThisNavRef.current = true
        setArticleIdsAsViewed(usedIdsRef.current)
        return destroy
    }, [parsedWidgets, count, isTransitioning, enabled])

    useRouteChangeComplete(() => {
        populatedThisNavRef.current = false
    })

    // console.log({ count, widgetIds }, { widgetsData, parsedWidgets, data })

    return { data }
}

export const useMergedExchangeWidgetConfig = ({
    template,
    exchangeKey,
    count,
    areaXs,
    areaMd,
    columnsXs,
    columnsMd,
    config
}) =>
    useMemo(() => {
        const exchangeKeys = (exchangeKey && Array.isArray(exchangeKey) ? exchangeKey : [exchangeKey]).filter(Boolean)

        return {
            areaXs: template?.areaXs || areaXs,
            areaMd: template?.areaMd || areaMd,
            columnsXs: template?.columnsXs || columnsXs,
            columnsMd: template?.columnsMd || columnsMd,
            count: template?.count || count || exchangeKeys?.length || 0,
            config: template?.config || config,
            exchangeKeys
        }
    }, [template, areaXs, areaMd, columnsXs, columnsMd, count, config, exchangeKey])
