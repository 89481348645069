import { useEffect, useState } from 'react'

type AfterRenderCycleProps = {
    callback: () => void
    // cycles?: number
}
const cycles = 1 // @NOTE: if more than one become necessary, re-enable the prop and test if it works as intended
export const AfterRenderCycle = ({ callback }: AfterRenderCycleProps) => {
    const [rendered, setRendered] = useState(0)
    useEffect(() => {
        if (rendered < cycles) {
            setRendered(prev => prev + 1)
            return
        }
        callback()
    }, [callback, rendered, cycles])
    return null
}
