import { useMemo } from 'react'
import styled from '@emotion/styled'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { initialDataHardkooNet } from '@hmn/rtl-web-core/hooks/useImagesConfig'
import { generateSingleImageURL } from '@hmn/rtl-web-core/hooks/useImageURL'

import { Link } from '../Link'
import styles from './Columnist.style'

const ColumnistStyled = styled.div(props => ({ ...styles(props) }))

function Columnist({ author, title, button, isTransparent, isColumnist, ...rest }) {
    const customImageUrl = useMemo(
        () =>
            author?.image?.id
                ? generateSingleImageURL({
                      imageId: author?.image?.id,
                      variations: initialDataHardkooNet,
                      ratio: '6:7',
                      width: 768,
                      height: 768,
                      type: 'webp'
                  })
                : null,
        [author?.image?.id]
    )
    if (!author) {
        return null
    }

    const authorImageUrl = customImageUrl ?? author?.profileImage?.url
    const authorLead = author.extended_attributes?.author_lead

    return (
        <Link href={`/${isColumnist ? 'kolumnisti' : 'autori'}/${author?.slug}`}>
            <ColumnistStyled
                id="columnist_container"
                {...rest}
                className={clsx(button && 'hasButton')}
                hasButton={!!button}
                isTransparent={isTransparent}>
                {(title || button) && <div className="background" />}
                {authorImageUrl && <img src={authorImageUrl} alt={author?.title} />}
                <div className={clsx('container', !authorImageUrl && 'has_no_image')}>
                    <div className="nameTitleContainer">
                        <p className={clsx('name', !authorLead && 'has_no_role')} id="columnist_name">
                            {author.title}
                        </p>
                        {authorLead && <p className="role">{authorLead}</p>}
                    </div>
                    {title && <h1 className="title">{title}</h1>}
                    {button && button}
                </div>
            </ColumnistStyled>
        </Link>
    )
}

Columnist.propTypes = {
    title: PropTypes.string,
    pageTotal: PropTypes.number,
    author: PropTypes.oneOfType([() => null, PropTypes.object]),
    button: PropTypes.oneOfType([() => null, PropTypes.object]),
    isTransparent: PropTypes.bool,
    isColumnist: PropTypes.bool
}

Columnist.defaultProps = {
    title: PropTypes.string,
    pageTotal: 0,
    author: undefined,
    button: undefined,
    isTransparent: undefined,
    isColumnist: true
}

export default withErrorBoundary(Columnist, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[Columnist]: ', error, componentStack)
    }
})
