import { useRouter } from 'next/router'
import { useMemo } from 'react'

type CategoryMapping<T> = {
    [key: string]: T
    default: T
}

export const parseCategoryMapping = <T>(mapping: CategoryMapping<T> | T): CategoryMapping<T> => {
    const isMapped = mapping && typeof mapping === 'object' && Object.keys(mapping).includes('default')
    if (!isMapped) return { default: mapping as T }
    return mapping as CategoryMapping<T>
}

export const getCategoryMapping = <T>(
    mapping: CategoryMapping<T>,
    categorySlugsInput: string[] | string | undefined
): T => {
    if (!categorySlugsInput) return mapping.default
    const categorySlugs = Array.isArray(categorySlugsInput) ? categorySlugsInput : [categorySlugsInput]
    for (let i = categorySlugs.length; i > 0; i--) {
        const slug = categorySlugs.slice(0, i).join('/')
        if (mapping[slug]) return mapping[slug]
    }
    return mapping.default
}

export default <T>(mapping: CategoryMapping<T> | T): T => {
    const router = useRouter()
    const categoryMapping = useMemo(() => {
        const parsedMapping = mapping && parseCategoryMapping(mapping)
        return getCategoryMapping(parsedMapping, router.query.slugs)
    }, [mapping, router.query.slugs])
    return categoryMapping
}
