// eslint-disable-next-line import/no-extraneous-dependencies
import { useMemo } from 'react'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import {
    ExchangeWidget,
    exchangeWidgetDefaultProps,
    exchangeWidgetPropTypes,
    useMergedExchangeWidgetConfig
} from '@hmn/rtl-web-core/components/ExchangeWidget'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { config as exchangeWidgetConfigTemplates, variants as exchangeWidgetVariants } from './ExchangeWidget.config'

const DynamicCardsBlock = dynamic(() => import('../CardsBlock/CardsBlock.component'))

const DynamicArticleCard = dynamic(() => import('../Article/components/Card/Card.component'))

function ExchangeWidgetNet({ variant, ...rest }) {
    const [isDesktop] = useBreakpoints('md')
    const finalVariantTemplate = useMemo(() => {
        const desktopVariant = exchangeWidgetConfigTemplates[variant]
        const mobileVariant = exchangeWidgetConfigTemplates[`${variant}-mob`]
        return (!isDesktop && mobileVariant) || desktopVariant
    }, [isDesktop, variant])

    const mergedConfig = useMergedExchangeWidgetConfig({
        template: finalVariantTemplate,
        ...rest
    })
    return (
        <ExchangeWidget
            {...rest}
            {...mergedConfig}
            ArticleCardComponent={DynamicArticleCard}
            CardsBlockComponent={DynamicCardsBlock}
        />
    )
}
ExchangeWidgetNet.propTypes = {
    ...exchangeWidgetPropTypes,
    variant: PropTypes.oneOf([...Object.values(exchangeWidgetVariants)])
}

ExchangeWidgetNet.defaultProps = {
    ...exchangeWidgetDefaultProps,
    variant: undefined
}
export default withErrorBoundary(ExchangeWidgetNet, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[ExchangeWidgetNet]: ', error, componentStack)
    }
})
