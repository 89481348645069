import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, variant }) => {
    const config = {
        xs: {
            item: {
                variant: {
                    alpha: {
                        marginBottom: toRem(10)
                    },
                    beta: {
                        marginBottom: toRem(20)
                    }
                }
            }
        },
        md: {
            item: {
                variant: {
                    alpha: {
                        marginBottom: toRem(42)
                    },
                    beta: {
                        marginBottom: toRem(30)
                    }
                }
            }
        }
    }

    return {
        position: 'relative',
        '& .netGalleryCardsBlock_inner': {
            position: 'relative'
        },
        '& .netGalleryCardsBlock_list': {
            marginTop: toRem(30),
            [theme.breakpoints.up('md')]: {
                marginTop: toRem(40)
            }
        },
        '& .netGalleryCardsBlock_item': {
            marginBottom: config?.xs.item.variant[variant]?.marginBottom,
            [theme.breakpoints.up('md')]: {
                marginBottom: config?.md.item.variant[variant]?.marginBottom
            }
        },
        '& .netGalleryCardsBlock_button': {
            '&_icon': {
                paddingTop: toRem(2),
                width: toRem(16),
                height: toRem(16)
            }
        }
    }
}

export default style
