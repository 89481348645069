import { FC, useMemo } from 'react'

import Debug from '../components/Debug/Debug.component'
import { AppName, StrictAppNames } from '../types/app'

// @TODO: define and narrow down these types
type InsertElementConfig = any
type BlockName = string
type UseInsertExchangeProps = {
    appName?: StrictAppNames
    key: string | string[] | string[][] // single key over multiple instances, single keys per instance or multiple keys per instance
    count?: number
    articlesPerInsert?: number
    every?: number
    minimumBlocks?: number
    insertAtLeast?: number
    afterBlocks?: BlockName[]
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ExchangeComponent?: FC<any>
    variant?: string
}

const useInsertExchange = ({
    appName = AppName.RTL,
    key,
    articlesPerInsert = 1,
    every = 6,
    minimumBlocks = 8,
    insertAtLeast = 0,
    count = 1,
    afterBlocks = ['core/paragraph', 'core/columns', 'core/embed', 'hmn/article', 'hmn/poll'],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ExchangeComponent = Debug,
    variant = 'in-article-alpha'
}: UseInsertExchangeProps): undefined | InsertElementConfig => {
    return useMemo(() => {
        if (!key.length) {
            return
        }

        return {
            offset: 0,
            every,
            minimumBlocksInPage: minimumBlocks,
            insertAtLeast,
            afterBlocks,
            count, // count defaults to amount of elements to be inserted
            elements: Array.from({ length: count }).map((v, index) => {
                const thisInsertKey = Array.isArray(key) ? key[index % key.length] : key
                return <ExchangeComponent exchangeKey={thisInsertKey} variant={variant} inArticle></ExchangeComponent>
            })
        }
    }, [
        appName,
        key,
        articlesPerInsert,
        every,
        minimumBlocks,
        insertAtLeast,
        count,
        afterBlocks,
        ExchangeComponent,
        variant
    ])
}

export default useInsertExchange
