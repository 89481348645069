const style = ({ theme }) => ({
    width: '100%',
    marginBottom: '30px',
    [theme.breakpoints.up('md')]: {
        marginBottom: '20px'
    },
    '.galleryHead': {
        '&__title': {
            padding: '0px 15px',
            marginBottom: '11px',
            [theme.breakpoints.up('md')]: {
                padding: 0,
                marginBottom: '35px'
            }
        },

        '&__image': {
            marginBottom: '10px',
            cursor: 'pointer'
        },

        '&_inpicture_wrap': {
            position: 'relative'
        },

        '& .inpicture': {
            position: 'absolute',
            bottom: 0,
            left: '50%',
            marginBottom: '10px',
            transform: 'translateX(-50%)'
        },

        '&__lead': {
            marginTop: '15px',
            marginBottom: '15px',
            padding: '0px 15px',
            [theme.breakpoints.up('md')]: {
                marginTop: '21px',
                marginBottom: '18px',
                padding: 0
            }
        },

        '&__lead > p': {
            fontWeight: 400,
            lineHeight: 1.4,
            letterSpacing: '-0.03em',
            fontSize: '22px',
            [theme.breakpoints.up('md')]: {
                fontSize: '30px'
            }
        },

        '&__meta': {
            padding: '0px 15px',
            marginTop: '11px',
            [theme.breakpoints.up('md')]: {
                marginTop: '21px',
                padding: 0
            }
        }
    },

    '.galleryThumbs': {
        overflow: 'hidden',
        display: 'flex',

        '&__item': {
            flex: '0 0 auto',
            width: '60px',
            height: '60px',
            marginTop: '9px',
            marginRight: '9px',
            cursor: 'pointer',
            '&:last-child': {
                marginRight: 0
            },

            [theme.breakpoints.up('md')]: {
                width: '80px',
                height: '80px'
            }
        },

        '&__img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover'
        }
    }
})

export default style
