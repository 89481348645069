import React, { useMemo } from 'react'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { isAmpCompatible, replaceTagsForAmp } from '@hmn/rtl-web-core/helpers/amp'

function TrackingCode({ className, code }) {
    const isAmp = useAmp()
    const parsedCode = useMemo(() => {
        if (!code || (isAmp && !isAmpCompatible(code))) {
            return null
        }
        return isAmp ? replaceTagsForAmp(code) : code
    }, [isAmp, code])
    if (!parsedCode) {
        return null
    }
    // eslint-disable-next-line react/no-danger
    return <div className={className} dangerouslySetInnerHTML={{ __html: parsedCode }} />
}

TrackingCode.propTypes = {
    className: PropTypes.string,
    code: PropTypes.string
}

TrackingCode.defaultProps = {
    className: undefined,
    code: undefined
}
export default withErrorBoundary(TrackingCode, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[TrackingCode]: ', error, componentStack)
    }
})
