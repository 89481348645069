import createFastContext from './createFastContext'

type ModalGalleryContextState = {
    clickedImageIndex: number | null
    galleryId: string | null
}

const initialState: ModalGalleryContextState = {
    clickedImageIndex: null,
    galleryId: null
}

export const ModalGalleryContext = createFastContext(initialState)
export const useModalGalleryState = () => ModalGalleryContext.useSelector()
const dispatchImageIndex = (value: number) => ({ clickedImageIndex: value })
const dispatchGalleryId = (value: string) => ({ galleryId: value })

export const useGalleryIdDispatch = () => ModalGalleryContext.useDispatch(dispatchGalleryId)
export const useClickedImageIndexDispatch = () => ModalGalleryContext.useDispatch(dispatchImageIndex)
