import PropTypes from 'prop-types'

import { EmbedsProvider } from '@hmn/rtl-web-core/context/embeds'
import { ARCHIVE_REGEXP, UUID_REGEXP } from '@hmn/rtl-web-core/next-config/regexp'

import { RssAutodiscovery } from '@hmn/rtl-net-ui/components/RssAutodiscovery'

import { Archive } from '../components/Layouts/ArchiveLayout'
import Article from '../components/Layouts/ArticleLayout'
import { Category } from '../components/Layouts/CategoryLayout'
import ColumnLayout from '../components/Layouts/ColumnLayout'
import Embed from '../components/Layouts/EmbedLayout'
import Gallery from '../components/Layouts/GalleryLayout'
import HunkOfTheDay from '../components/Layouts/HunkOfTheDayLayout'
import Video from '../components/Layouts/VideoLayout'
import Zodiac from '../components/Layouts/ZodiacLayout'
import { getServerSideProps as archiveGetServerSideProps } from '../fake-pages/category/archive'
import { getServerSideProps as categoryGetServerSideProps } from '../fake-pages/category/index'
import { getServerSideProps as entityGetServerSideProps } from '../fake-pages/entity/index'

export default function CatchAll({ catchAll, ...props }) {
    const { entity, category, archive } = catchAll

    if (entity) {
        const { pageProps, pageComponentName, entitySitemapNode } = props

        const Component = (() => {
            switch (pageComponentName) {
                case Video.displayName:
                    return Video
                case Gallery.displayName:
                    return Gallery
                case Article.displayName:
                    return Article
                case ColumnLayout.displayName:
                    return ColumnLayout
                case Zodiac.displayName:
                    return Zodiac
                case HunkOfTheDay.displayName:
                    return HunkOfTheDay
                case Embed.displayName:
                    return Embed
                default:
                    throw new Error(`Component with name '${pageComponentName}' is not found.`)
            }
        })()

        return (
            <EmbedsProvider embeds={pageProps.context?.embeds}>
                <RssAutodiscovery sitemap={entitySitemapNode} />
                <Component {...pageProps} />
            </EmbedsProvider>
        )
    }

    if (category && archive) {
        return <Archive {...props} />
    }

    return <Category {...props} />
}

CatchAll.propTypes = {
    catchAll: PropTypes.shape({
        entity: PropTypes.bool.isRequired,
        category: PropTypes.bool.isRequired,
        archive: PropTypes.bool.isRequired
    }).isRequired,
    pageProps: PropTypes.shape({
        context: PropTypes.shape({
            embeds: PropTypes.shape({})
        })
    }),
    pageComponentName: PropTypes.string,
    entitySitemapNode: PropTypes.shape({})
}

CatchAll.defaultProps = {
    pageProps: {},
    pageComponentName: undefined,
    entitySitemapNode: {}
}

const entityRegex = new RegExp(UUID_REGEXP, 'gmi')
const archiveRegex = new RegExp(ARCHIVE_REGEXP, 'gmi')

const PAGES_WITHOUT_CMP = ['/info/pravila-privatnosti', '/info/politika-kolacica']
const CMP_DISABLED_ROUTE_REGEX = new RegExp(PAGES_WITHOUT_CMP.join('|'), 'gim')

export async function getServerSideProps(ctx) {
    const { req } = ctx

    const entityUUIDs = req.url.match(entityRegex)

    if (entityUUIDs) {
        const entityServerSideProps = await entityGetServerSideProps(ctx)

        if (entityServerSideProps.notFound) {
            return entityServerSideProps
        }

        if (entityServerSideProps.redirect) {
            return entityServerSideProps
        }

        return {
            props: {
                catchAll: {
                    category: false,
                    entity: true,
                    archive: false
                },
                ...entityServerSideProps.props
            }
        }
    }

    if (req.url.match(archiveRegex)) {
        const archiveServerSideProps = await archiveGetServerSideProps(ctx)

        if (archiveServerSideProps.notFound) {
            return archiveServerSideProps
        }

        if (archiveServerSideProps.redirect) {
            return archiveServerSideProps
        }

        return {
            props: {
                catchAll: {
                    category: true,
                    entity: false,
                    archive: true
                },
                ...archiveServerSideProps.props
            }
        }
    }

    const categoryServerSideProps = await categoryGetServerSideProps(ctx)

    if (categoryServerSideProps.notFound) {
        return categoryServerSideProps
    }

    if (categoryServerSideProps.redirect) {
        return categoryServerSideProps
    }

    const disableDidomi = !!req.url.match(CMP_DISABLED_ROUTE_REGEX)

    return {
        props: {
            catchAll: {
                category: true,
                entity: false,
                archive: false
            },
            ...categoryServerSideProps.props,
            disable: [disableDidomi && 'didomi'].filter(Boolean)
        }
    }
}
