const generatePositions = (positions, config) =>
    positions.map(position => ({
        ...config,
        position
    }))

// const generateStringArray = (templateFunction, count, start = 0) =>
//     Array.from({ length: count }).map((_, i) => templateFunction(i + start))

const insertAds = [
    {
        every: 6,
        offset: -3,
        afterBlocks: ['core/paragraph', 'core/columns', 'core/embed'],
        insertAtLeast: 2,
        elements: generatePositions(
            [
                'In article - 1',
                // 'AdmiralHardcoded',
                ['In article - top', 'In article - top - alt'],
                'In article - 2',
                'In article - 3',
                ['In article - hardcoded - 2', 'In article - hardcoded - 2 - alt'],
                'In article - 4',
                ['In article - bottom', 'In article - bottom - alt']
            ],
            {
                desktopOnly: true,
                disablePlaceholder: true,
                disableFixedHeight: true
            }
        )
    },
    {
        every: 6,
        offset: -5,
        afterBlocks: ['core/paragraph', 'core/columns', 'core/embed'],
        insertAtLeast: 2,
        elements: generatePositions(
            // generateStringArray(i => `In article - ${i}`, 7, 1),
            [
                'In article - 1',
                // 'AdmiralHardcoded',
                'In article - 2',
                'In article - 3',
                'In article - 4',
                'In article - 5',
                'In article - 6',
                'In article - 7'
            ],
            {
                mobileOnly: true,
                disablePlaceholder: true,
                disableFixedHeight: true
            }
        )
    }
]

// WARNING: slots defined here will be defined without care if the slot is actually displayed in the end
// check any edits with googletag.openConsole()
// slot must be used in markup in order for valid define - load - display sequence of events
// if you wish to define/add a new slot on a page, make sure that it's added both here and in the markup
// eg. <AdSlot {...staticAds.halfpage1} />
// or deconstruct/reconstruct the staticAds object before passing it to useDfpSlots
const staticAds = {
    halfpage1: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 1',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            contentWidth: '100%'
        }
    },
    halfpage2: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 2'
        // isSliding: true,
        // slidingProps: {
        //     offsetTop: 70,
        //     offsetBottom: 50,
        //     contentWidth: '100%',
        //     renderInSmallSpace: false,
        //     minimumFrameHeight: 600
        // }
    },

    halfpage2alt: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 2',
        isSliding: true,
        slidingProps: {
            offsetTop: 70,
            offsetBottom: 50,
            contentWidth: '100%'
        }
    },
    halfpage3: {
        alternateBg: true,
        maxAdWidth: '300px',
        position: 'Floating - 3',
        isSliding: false
    },
    billboardTop: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: 'HC - Billboard - top'
    },
    billboard1: {
        amp: true,
        position: 'Billboard - 1'
    },
    billboard2: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        amp: true,
        position: 'Billboard - 2'
    },
    outOfPage1: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - left' // @TODO replace with OOP cms input once available
    },
    outOfPage2: {
        desktopOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Wallpaper - right' // @TODO replace with OOP cms input once available
    },
    outOfPage3: {
        mobileOnly: true,
        onePxSlot: true,
        renderOutOfPage: true,
        position: 'Floating' // @TODO replace with OOP cms input once available
    },
    anchor: {
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Anchor'
    },
    interstitial: {
        // mobileOnly: true,
        // onePxSlot: true,
        renderOutOfPage: true,
        position: 'Hardcoded Interstitial'
    },
    galleryMobileRectangle: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        mobileOnly: true,
        position: 'Hardcoded Gallery - Mobile Rectangle'
    },
    articleBottom: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        mobileOnly: true,
        position: 'Article Bottom'
    },
    inPicture: {
        disablePlaceholder: true,
        disableFixedHeight: true,
        position: 'Article In Picture'
    },
    galleryModal: {
        position: 'Article Gallery Modal'
    }
}

export { staticAds, insertAds }
