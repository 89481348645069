import React, { useMemo } from 'react'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { getArticleTypeName } from '@hmn/rtl-web-core/helpers/article/getArticleType'
import { useCrossDomainCommunication as usePreview } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import { IsolatedEntityThirdParties } from '@hmn/rtl-web-core/hooks/IsolatedEntityThirdParties'

import { staticAds } from '@hmn/rtl-net-ui/components/Ad/config/article'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import AdNow from '@hmn/rtl-net-ui/components/AdNow/AdNow.component'
import { articleHeadVariants, ArticleTags } from '@hmn/rtl-net-ui/components/Article/components'
import { Button } from '@hmn/rtl-net-ui/components/Button'
import Newsletter from '@hmn/rtl-net-ui/components/Cta/components/Newsletter/Newsletter.net.component'
import { PrevNext, prevNextVariants } from '@hmn/rtl-net-ui/components/Navigation/components'
import { TrackingCode } from '@hmn/rtl-net-ui/components/TrackingCode'

import { BaseLayout } from '../../layouts'
import { Seo } from '..'
import CombinedArticleHead from '../CombinedArticleHead'
import { getPrevNextHref } from '../PrevNextArticle/PrevNextArticle.component'

const { billboard1, halfpage1, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds

function HunkOfTheDay({ ssrData, navigation }) {
    const isAmp = useAmp()
    useEntityTypeDispatch('general')

    const { article } = ssrData

    const { data: articleWithPreview } = usePreview(article, {
        type: 'preview',
        source: '@CMS'
    })

    const articleTags = useMemo(() => article?.tags || [], [article?.tags])

    const pageCategory = articleWithPreview?.sitemap?.href?.split('/')[2]
    const articleType = getArticleTypeName(articleWithPreview, 'nethr')

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedEntityThirdParties
                entity={articleWithPreview}
                articleType={articleType}
                entityType="hunk-of-the-day"
                appName="net.hr"
                defaultGemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                ampGtmId={process.env.NEXT_PUBLIC_AMP_GTM_ID_NET}
            />

            <Seo hasAmp item={articleWithPreview} />
            <TrackingCode code={article?.extended_attributes?.tracking_code} />
            <Row variant="djevojkaDana.main">
                <Row variant="djevojkaDana.content.container">
                    <Column variant="djevojkaDana.content.main">
                        <CombinedArticleHead
                            article={articleWithPreview}
                            columnVariant="djevojkaDana.head"
                            headVariant={articleHeadVariants.GAMMA}
                            isHunk
                        />

                        {isAmp && (
                            <Column variant="article.returnFromAmp">
                                <Button
                                    title="Idi na originalni članak"
                                    className="fun_text_button"
                                    widthMobile="100%"
                                    isInverted
                                    href={articleWithPreview?.sitemap?.href}>
                                    Idi na originalni članak
                                </Button>
                            </Column>
                        )}
                        {articleTags ? (
                            <Column className="tags" variant="djevojkaDana.tags">
                                <ArticleTags tags={articleTags} isHunk />
                            </Column>
                        ) : null}
                        <Column variant="djevojkaDana.ads.billboard">
                            <Media className="Sidebar_aside" lessThan="md" scaleDown>
                                <AdSlot {...billboard1} />
                            </Media>
                        </Column>
                    </Column>

                    {/* ========= SIDEBAR ====== */}
                    {!isAmp && (
                        <Column variant="djevojkaDana.content.aside">
                            <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                                <AdSlot {...halfpage1} />
                            </Media>
                        </Column>
                    )}
                </Row>
                {!isAmp && (
                    <Row variant="article.newsletter">
                        <Newsletter />
                    </Row>
                )}
                {articleWithPreview?.extended_attributes?.display_third_party_content && (
                    <Row variant="article.linker">
                        <AdNow />
                    </Row>
                )}
            </Row>
            <Row variant="djevojkaDana.prevNext">
                <PrevNext
                    previousButtonTitle={
                        pageCategory === 'overkloking' || pageCategory === 'komnetar' ? 'PRETHODNI' : 'PRETHODNA'
                    }
                    previousHref={getPrevNextHref(articleWithPreview?.prevNext?.prev?.uri)}
                    previousArticleTitle={articleWithPreview?.prevNext?.prev?.title}
                    nextButtonTitle={
                        pageCategory === 'overkloking' || pageCategory === 'komnetar' ? 'SLJEDEĆI' : 'SLJEDEĆA'
                    }
                    nextHref={getPrevNextHref(articleWithPreview?.prevNext?.next?.uri)}
                    nextArticleTitle={articleWithPreview?.prevNext?.next?.title}
                    variant={prevNextVariants.BETA}
                    isHunk
                    sticky
                />
            </Row>

            {!isAmp && (
                <Row variant="zeroDimensions">
                    <AdSlot {...outOfPage1} />
                    <AdSlot {...outOfPage2} />
                    <AdSlot {...outOfPage3} />
                    <AdSlot {...staticAds.anchor} />
                    <AdSlot {...interstitial} />
                </Row>
            )}
        </BaseLayout>
    )
}

HunkOfTheDay.propTypes = {
    messageType: PropTypes.string,
    messageSource: PropTypes.string,
    ssrData: PropTypes.shape({
        video: PropTypes.shape({}),
        article: PropTypes.shape({
            tags: PropTypes.arrayOf(PropTypes.shape({})),
            extended_attributes: PropTypes.shape({
                tracking_code: PropTypes.string
            })
        }),
        image: PropTypes.shape({}),
        videoElastic: PropTypes.shape({}),
        isVideoPreview: PropTypes.bool
    }),
    navigation: PropTypes.shape({}).isRequired
}

HunkOfTheDay.defaultProps = {
    messageType: undefined,
    messageSource: undefined,
    ssrData: undefined
}

HunkOfTheDay.displayName = 'HunkOfTheDay'

export default HunkOfTheDay
