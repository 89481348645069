// @TODO: maybe separate this out into the apps and instantiate via parent hook {app}/hooks/...
// since this way the whole map is bundled into every app.
// Not really a concern in this case but a concerning pattern in a more complex one
const hardcodedArticleTypeNameMap = {
    all: {
        'article-type-pr-article': 'PR article',
        'article-type-joke': 'Vic dana',
        'article-type-prev-next': 'Prev Next article',
        'article-type-horoscope': 'Default article'
    },
    rtlhr: {
        'article-type-pr-article': 'PR article',
        'article-type-joke': 'Vic dana',
        'article-type-prev-next': 'Default article',
        'article-type-horoscope': 'Default article'
    },
    nethr: {
        'article-type-pr-article': 'PR article',
        'article-type-joke': 'Vic dana',
        'article-type-prev-next': 'Prev Next article',
        'article-type-girl-of-the-day': 'Cura dana',
        'article-type-hunk-of-the-day': 'Decko dana',
        'article-type-overclocking': 'Overkloking',
        'article-type-commnet': 'Komnetar'
    },
    zenahr: {}
}
const getArticleType = article => article?.taxons?.['article-type']?.[0]?.slug || null

export const getArticleTypeName = (type, appName = 'all', customNameMap = undefined) =>
    customNameMap?.[type] || hardcodedArticleTypeNameMap?.[appName]?.[type] || 'Default article'

export default getArticleType
