// @TODO: Label component can have no lines variation instead doing it with css here
import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme }) => ({
    WebkitTapHighlightColor: 'transparent',
    position: 'relative',
    paddingBottom: toRem(40),
    background: theme.colors.background.default,
    // pointerEvents: 'none',
    '& .title_subtitle': {
        fontSize: toRem(24),
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(32)
        }
    },
    '& .title_title': {
        fontSize: toRem(24),
        [theme.breakpoints.up('md')]: {
            fontSize: toRem(32)
        }
    },
    [theme.breakpoints.up('md')]: {
        padding: '0 25px'
    },
    '& .galleryContentNet_wrapper': {
        maxWidth: '800px',
        margin: 'auto'
    },
    '& .galleryContentNet_ad': {
        width: '100%',
        height: 'auto',
        minHeight: '250px',
        margin: 'auto',

        '> .marginCollapse': {
            marginBottom: '0'
        }
    },
    '& .galleryContentNet_head': {
        marginBottom: '15px',
        [theme.breakpoints.down('md')]: {
            padding: '0 15px'
        },
        '.title': {
            [theme.breakpoints.up('md')]: {
                lineHeight: toRem(38)
            }
        }
    },
    '& .galleryContentNet_lead': {
        marginTop: toRem(8),

        [theme.breakpoints.down('md')]: {
            padding: '0 15px'
        },

        [theme.breakpoints.up('md')]: {
            marginTop: '13px'
        },
        '& p': {
            color: theme.colors.text.default,
            fontSize: toRem(16),
            fontWidth: 400,
            lineHeight: 1.4
        }
    },
    '& .swiperNav_': {
        '&prev, &next': {
            visibility: 'hidden',
            position: 'absolute',
            top: '150px',
            backgroundColor: '#000000',
            opacity: 0.5,
            color: theme.colors.text.default,
            zIndex: theme.zIndex.over,
            pointerEvents: 'initial',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            svg: {
                width: '36px',
                height: '72px',
                path: {
                    fill: 'currentColor'
                }
            }
        },
        '&prev': {
            left: 0
        },
        '&next': {
            right: 0
        }
    },
    '& .swiper-pagination': {
        position: 'absolute',
        zIndex: theme.zIndex.over,
        top: '5px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    '& .swiper-pagination-bullet': {
        background: theme.colors.text.white,
        opacity: 1
    },
    '& .swiper-pagination-bullet-active': {
        background: theme.colors.text.accent
    },
    '.swiper:hover': {
        '.swiperNav_prev, .swiperNav_next': {
            visibility: 'visible'
        },
        cursor: 'pointer'
    }
})

export default style
