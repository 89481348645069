export const weekdayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
export const monthNames = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
]

const dayNameMap = {
    // en
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 0
}

const engMonthNameMap = {
    // en
    january: 0,
    february: 1,
    march: 2,
    april: 3,
    may: 4,
    june: 5,
    july: 6,
    august: 7,
    september: 8,
    october: 9,
    november: 10,
    december: 11
}

const monthNameMap = {
    // en
    ...engMonthNameMap,
    // hr
    sijecanj: 0,
    siječanj: 0,
    veljaca: 1,
    veljača: 1,
    ozujak: 2,
    ožujak: 2,
    travanj: 3,
    svibanj: 4,
    lipanj: 5,
    srpanj: 6,
    kolovoz: 7,
    rujan: 8,
    listopad: 9,
    studeni: 10,
    prosinac: 11
}

export const nameIndexMap = { ...dayNameMap, ...monthNameMap }
