import { PropsWithChildren } from 'react'

export type DebugComponentProps = PropsWithChildren<{
    [key: string]: any
}>

export const Debug = ({ children, ...rest }: DebugComponentProps) => {
    return (
        <div style={{ border: 'blue dashed 2px' }}>
            Debug component:
            <br />
            <pre style={{ fontSize: 9 }}>{JSON.stringify(rest, null, 2)}</pre>
            <br />
            {children}
        </div>
    )
}

export default Debug
