import dayjs from 'dayjs'

/**
 * Check if input value is a valid date
 * @param {*} date
 * @returns {boolean}
 */
const isValidDate = date => {
    if (!date) {
        return false
    }
    return dayjs(date).isValid()
}

export default isValidDate
