import { useEffect, useState } from 'react'
import { useUIDSeed } from 'react-uid'
import PropTypes from 'prop-types'

import {
    ArticleCard,
    articleCardContentMarkerVariants,
    articleCardSizeVariants,
    articleCardVariants
} from '@hmn/rtl-net-ui/components/Article/components'
import { cardsBlockVariants, GalleryCardsBlock } from '@hmn/rtl-net-ui/components/Gallery/components'

const gtmDataMostViewed = {
    eventCategory: 'Video',
    eventAction: 'Najgledanije',
    eventLabel: 'Below content'
}

export function MostViewedVideos({ excludeId = undefined }) {
    const uid = useUIDSeed()

    const [data, setData] = useState([])

    useEffect(() => {
        const searchParams = new URLSearchParams([['limit', 5]])

        if (excludeId) {
            searchParams.append('exclude', excludeId)
        }

        fetch(`/api/most-viewed-videos?${searchParams.toString()}`).then(async response => {
            if (!response.ok) {
                // eslint-disable-next-line no-console
                console.error(response.body)
                return
            }

            const entities = await response.json()

            setData(entities)
        })
    }, [excludeId])

    if (!data?.length) {
        return null
    }

    return (
        <GalleryCardsBlock
            title="Najgledanije"
            titleTagComponent="h4"
            displayButton={false}
            variant={cardsBlockVariants.BETA}>
            {data.map((item, index) => (
                <ArticleCard
                    contentMarkerVariant={articleCardContentMarkerVariants.VIDEO}
                    item={item}
                    key={uid(item?.id || index)}
                    href={item?.sitemap?.href}
                    variant={articleCardVariants.ALPHA}
                    sizeVariant={articleCardSizeVariants['1x2']}
                    imageWidth={90}
                    imageHeight={60}
                    gtmData={gtmDataMostViewed}
                    titleTagComponent="h3"
                />
            ))}
        </GalleryCardsBlock>
    )
}

MostViewedVideos.propTypes = {
    excludeId: PropTypes.string
}

MostViewedVideos.defaultProps = {
    excludeId: undefined
}
