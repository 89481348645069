// @TODO: Fix issue with image (width, height, ratio)
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import useDeviceType from '@hmn/rtl-web-core/hooks/layout/useDeviceType'

import { HtmlContent } from '../../../HtmlContent'
import { Image, imageRatioVariants } from '../../../Image'
import styles from './Item.style'

const ItemStyled = styled.div(props => ({ ...styles(props) }))

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Item({ image, className, totalSlides, slideIndex, activeIndex, showImageInfo, isInitialSlide, ...rest }) {
    const title = image?.name || ''
    const intro = image?.description || ''

    const isActiveImage = slideIndex === activeIndex
    const [deviceType] = useDeviceType()

    return (
        <ItemStyled
            className={className}
            isTitle={!!title}
            {...rest}
            id={`swiper_slide_inner_${slideIndex}`}
            isActive={isActiveImage}
            isSSR={!deviceType}>
            <div className="galleryItemNet_wrapper" id={`galley_item_inner_${slideIndex}`}>
                <Image
                    image={image}
                    variation={imageRatioVariants.CUSTOM_ORIGINAL}
                    alt={title}
                    width={700}
                    height={405}
                    sizesMd={66}
                    sizesSm={100}
                    lazyLoad={false}
                    isGalleryLazyLoadImage={!isActiveImage}
                    preload={isActiveImage}
                    originalAspectRatio={image?.original_aspect_ratio}
                />
            </div>
            <div className="galleryItemNet_footer">
                {(title || intro) && (
                    <div className="galleryItemNet_desc">
                        {/* @NOTE this is temporary until hrvoje says otherwise */}
                        {/* {title && <HtmlContent data={title} className="galleryItemNet_title" />} */}
                        {intro && <HtmlContent data={intro} className="galleryItemNet_intro" />}
                    </div>
                )}
                {(slideIndex >= 0 && totalSlides && (
                    <div className="galleryItemNet_slide" id={`galley_item_slide_${slideIndex}`}>
                        <span className="galleryItemNet_slide_active" id="gallery_item_slide_active">
                            {slideIndex + 1}
                        </span>
                        <span className="galleryItemNet_slide_total" id="gallery_item_slide_total">
                            /{totalSlides}
                        </span>
                    </div>
                )) ||
                    null}
            </div>
        </ItemStyled>
    )
}

Item.propTypes = {
    className: PropTypes.string,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    slideIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    totalSlides: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showImageInfo: PropTypes.bool,
    isInitialSlide: PropTypes.bool,
    activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

Item.defaultProps = {
    className: undefined,
    image: undefined,
    slideIndex: 0,
    totalSlides: 0,
    showImageInfo: false,
    isInitialSlide: false,
    activeIndex: 0
}

export default withErrorBoundary(Item, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[GalleryItem]: ', error, componentStack)
    }
})
