import { responsive, toRem, toRems } from '../../helpers/theme'

const imgWidth = 120

const style = ({ theme, isTransparent, hasButton }) => ({
    position: 'relative',
    width: '100%',
    maxWidth: toRem(700),
    minHeight: '100px',

    '& .container': {
        display: 'flex',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        padding: toRems([12, 20, 12, imgWidth + 10]),
        // height: '90%',
        minHeight: '85px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        background: !isTransparent ? theme.colors.grey100 : 'transparent',
        [theme.breakpoints.up('lg')]: {
            // height: '60%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            top: 'auto'
        },

        '&.has_no_image': {
            paddingLeft: toRem(20)
        }
    },
    '& .background': {
        display: 'none',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 'auto',
        background: !isTransparent ? theme.colors.grey100 : 'transparent',
        [theme.breakpoints.up('md')]: {
            height: 'auto',
            padding: '5px 0'
        }
    },
    // '& .hasButton': {
    //     marginBottom: toRem(20),
    //     '& .background': {
    //         ...responsive([
    //             { height: '90%' },
    //             { height: '90%' },
    //             { height: '50%' },
    //             { height: '50%' },
    //             { height: '50%' }
    //         ])
    //     }
    // },
    ...(hasButton && {
        marginBottom: toRem(20),
        [theme.breakpoints.down('sm')]: {
            '& .container': {
                flexDirection: 'column'
            }
        }
    }),
    '& img': {
        width: toRem(imgWidth),
        height: toRem(140),
        objectFit: 'cover',
        objectPosition: 'bottom center',
        position: 'relative',
        zIndex: 1,
        filter: 'grayscale(100%)'
        // mixBlendMode: 'multiply;'
    },
    '& .Image-noPlaceholder': {
        paddingBottom: 0,
        position: 'relative'
    },

    '& .nameTitleContainer': {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: '10px'
    },
    '& .name': {
        color: theme.colors.netBlue,
        fontSize: toRem(20),
        fontWeight: 900,
        letterSpacing: toRem(-1),
        lineHeight: 1.2,

        [theme.breakpoints.down('sm')]: {
            '&.has_no_role': {
                marginBottom: '10px'
            }
        }
    },
    '& .role': {
        fontSize: toRem(14),
        // color: theme.colors.netGrey, // TODO: check why this is not working
        color: '#666766',
        lineHeight: toRem(14),

        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',

        [theme.breakpoints.up('sm')]: {
            paddingRight: '10px',
            margin: '7px 0 10px',
            lineHeight: toRem(17)
        }
    },
    '& .title': {
        textTransform: 'uppercase',
        ...responsive([
            { fontSize: toRem(24) },
            { fontSize: toRem(24) },
            { fontSize: toRem(32) },
            { fontSize: toRem(32) },
            { fontSize: toRem(32) }
        ])
    },

    '& .cta_button': {
        height: toRem(36),
        minHeight: toRem(30), // !important`,
        fontSize: toRem(14),
        textWrap: 'nowrap'
    }
})

export default style
