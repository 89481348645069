import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

import { articleCardSizeVariants, articleCardVariants } from '../../../Article/components/Card'
import { Button, netButtonVariants } from '../../../Button'
import { ArrowNetIcon, Icon } from '../../../Icon'
import { netSectionTitleSizeVariants, SectionTitle } from '../../../SectionTitle'
import styles from './CardsBlock.style'

const GalleryCardsBlockStyled = styled.section(props => ({ ...styles(props) }))
function GalleryCardsBlock({
    children,
    title,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    titleTagComponent: TitleTag,
    variant,
    className,
    href,
    isVideo,
    displayButton,
    ...rest
}) {
    const uid = useUIDSeed()
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])

    const getItemsNumber = type => {
        if (type === 'alpha') {
            return 10
        }
        return 5
    }

    if (!itemElements.length) {
        return null
    }

    return (
        <GalleryCardsBlockStyled
            variant={variant}
            className={className}
            id={title.toLowerCase()?.replace(/ /gim, '')}
            {...rest}>
            <div
                className="netGalleryCardsBlock_inner"
                id={`gallery_cards_inner_${title.toLowerCase()?.replace(/ /gim, '')}`}>
                {title && (
                    <SectionTitle sizeVariant={netSectionTitleSizeVariants['1x3']} title={title} titleTag={TitleTag} />
                )}
                <div
                    className="netGalleryCardsBlock_list"
                    id={`gallery_cards_list_${title.toLowerCase()?.replace(/ /gim, '')}`}>
                    {itemElements?.slice(0, getItemsNumber(variant)).map((child, index) => (
                        <div
                            key={uid(child?.props?.item?.id || index)}
                            className="netGalleryCardsBlock_item"
                            id={`gallery_cards_item_${child?.props?.item?.id}`}>
                            {cloneElement(child, {
                                variant: variant === 'alpha' ? articleCardVariants.ALPHA : articleCardVariants.BETA,
                                sizeVariant:
                                    variant === 'alpha'
                                        ? articleCardSizeVariants['1x2']
                                        : articleCardSizeVariants['1x6']
                            })}
                        </div>
                    ))}
                </div>
            </div>
            {displayButton && (
                <Button
                    variant={netButtonVariants.BETA}
                    heightMd={50}
                    heightXs={43}
                    href={href}
                    id={isVideo ? 'pogledaj_još_videa' : 'pogledaj_još_galerija'}
                    iconComponent={
                        <Icon className="netGalleryCardsBlock_button_icon" icon={ArrowNetIcon} viewBox="0 0 20 20" />
                    }>
                    {isVideo ? 'Pogledaj još videa' : 'Pogledaj još galerija'}
                </Button>
            )}
        </GalleryCardsBlockStyled>
    )
}

const cardsBlockVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta'
})

GalleryCardsBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleTagComponent: PropTypes.elementType,
    variant: PropTypes.oneOf([...Object.values(cardsBlockVariants)]),
    href: PropTypes.string,
    isVideo: PropTypes.bool,
    displayButton: PropTypes.bool
}
GalleryCardsBlock.defaultProps = {
    className: undefined,
    title: undefined,
    titleTagComponent: 'h1',
    variant: cardsBlockVariants.ALPHA,
    href: 'https://net.hr/',
    isVideo: false,
    displayButton: true
}

export { cardsBlockVariants }

export default withErrorBoundary(GalleryCardsBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[NetGalleryCardsBlock]: ', error, componentStack)
    }
})
