/* eslint-disable @typescript-eslint/no-unused-vars */
import { Children, cloneElement, isValidElement, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import styled from '@emotion/styled'
import { useAmp } from 'next/amp'
import PropTypes from 'prop-types'

import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import { articleCardSizeVariants, articleCardVariants } from '../Article/components/Card'
import { imageRatioVariants } from '../Image'
import { netSectionTitleSizeVariants, SectionTitle } from '../SectionTitle'
import { netTitleVariants } from '../Title'
import styles from './CardsBlock.style'

const NetCardsBlockStyled = styled.section(props => ({ ...styles(props) }))
function CardsBlock({
    children,
    title,
    titleVariant,
    titleTagComponent: TitleTag,
    areaXs,
    areaMd,
    columnsXs,
    columnsMd,
    count,
    config,
    variant,
    titleSizeVariant,
    blockColor,
    className,
    ...rest
}) {
    const isAmp = useAmp()
    const uid = useUIDSeed()
    const [_isDesktop] = useBreakpoints('md')
    const itemElements = useMemo(() => Children.toArray(children).filter(child => isValidElement(child)), [children])

    // @TODO: revisit this, seems to work fine for current use cases
    const minHeight = useMemo(() => {
        const items = Math.min(...[itemElements.length, count].filter(Boolean))
        const onePerColMd = columnsMd?.split(' ')?.length === items
        const onePerColXs = columnsMd?.split(' ')?.length === items
        const itemsInColMd = Math.ceil((items - 1) / (columnsMd?.split(' ').length || 1))
        const itemsInColXs = Math.ceil((items - 1) / (columnsXs?.split(' ').length || 1))

        return {
            md:
                (onePerColMd && 100) ||
                ((!columnsMd || columnsMd?.split(' ')?.length === 1) && 350 + 100 * itemsInColMd) ||
                Math.max(450, 100 * itemsInColMd),
            xs: (onePerColXs && 100) || 450 + 100 * itemsInColXs
        }
    }, [columnsMd, columnsXs, count, itemElements])

    return (
        <NetCardsBlockStyled
            areaXs={areaXs}
            areaMd={areaMd}
            columnsXs={columnsXs}
            columnsMd={columnsMd}
            blockColor={blockColor}
            className={className}
            minHeight={minHeight}
            id={`cards_block_wrapper_${title ? title?.toLowerCase()?.replace(/ /gim, '_') : 'članci'}`}
            isAmp={isAmp}
            {...rest}>
            <div
                className="netCardsBlock_inner"
                id={`cards_block_${title ? title?.toLowerCase()?.replace(/ /gim, '_') : 'članci'}`}>
                {title && (
                    <SectionTitle
                        sizeVariant={titleSizeVariant || netSectionTitleSizeVariants['1x3']}
                        title={title}
                        titleTag="h5"
                    />
                )}
                <div
                    className="netCardsBlock_list"
                    id={`cards_block_list_${title ? title?.toLowerCase()?.replace(/ /gim, '_') : 'članci'}`}>
                    {itemElements?.slice(0, count).map((child, index) => {
                        const cardConfig = config && (config[index] || config[config.length - 1])
                        return (
                            <div
                                key={uid(child?.props?.item?.id || index)}
                                className="netCardsBlock_item"
                                id={`cards_block_item_${child?.props?.item?.id}`}>
                                {cloneElement(child, {
                                    variant: articleCardVariants[`${cardConfig?.variant}`],
                                    sizeVariant: articleCardSizeVariants[`${cardConfig?.size}`],
                                    imageVariation: imageRatioVariants[`${cardConfig?.image}`],
                                    imageHeight: cardConfig?.imageHeight,
                                    imageWidth: cardConfig?.imageWidth,
                                    useUrlColor: cardConfig?.useUrlColor
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </NetCardsBlockStyled>
    )
}

const cardsBlockVariants = Object.freeze({
    ALPHA: 'alpha',
    BETA: 'beta',
    GAMMA: 'gamma',
    DELTA: 'delta'
})

CardsBlock.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    titleVariant: PropTypes.oneOf([...Object.values(netTitleVariants)]),
    titleTagComponent: PropTypes.elementType,
    areaXs: PropTypes.string,
    areaMd: PropTypes.string,
    columnsXs: PropTypes.string,
    columnsMd: PropTypes.string,
    count: PropTypes.number,
    config: PropTypes.oneOfType([() => null, PropTypes.arrayOf(PropTypes.object)]),
    variant: PropTypes.oneOf([...Object.values(cardsBlockVariants)]),
    titleSizeVariant: PropTypes.string,
    blockColor: PropTypes.string,
    border: PropTypes.bool
}
CardsBlock.defaultProps = {
    className: undefined,
    title: undefined,
    titleVariant: undefined,
    titleTagComponent: 'h1',
    areaXs: undefined,
    areaMd: undefined,
    columnsXs: undefined,
    columnsMd: undefined,
    count: 10,
    config: undefined,
    variant: cardsBlockVariants.ALPHA,
    titleSizeVariant: '1x3',
    blockColor: undefined,
    border: false
}

export { cardsBlockVariants, NetCardsBlockStyled }

export default withErrorBoundary(CardsBlock, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[CardsBlock]: ', error, componentStack)
    }
})
