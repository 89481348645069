import { Fragment, useMemo } from 'react'
import { useUIDSeed } from 'react-uid'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { Column, Row } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'
import { useCrossDomainCommunication as usePreview } from '@hmn/rtl-web-core/hooks'
import { useListingInsertAds } from '@hmn/rtl-web-core/hooks/article/useAds'
import { IsolatedUseCategoryThirdParties } from '@hmn/rtl-web-core/hooks/category/useCategoryThirdParties'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'
import useCurrentPageNumber from '@hmn/rtl-web-core/hooks/useCurrentPageNumber'

import { insertAds as insertAdsConfig, staticAds } from '@hmn/rtl-net-ui/components/Ad/config/listing'
import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'
import { ArchiveDatePicker } from '@hmn/rtl-net-ui/components/Archive'
import {
    ArticleCard,
    articleCardSizeVariants,
    articleCardVariants
} from '@hmn/rtl-net-ui/components/Article/components/Card'
import { ConditionalInsert } from '@hmn/rtl-net-ui/components/ConditionalInsert'
import { imageRatioVariants } from '@hmn/rtl-net-ui/components/Image'
import { Pagination } from '@hmn/rtl-net-ui/components/Pagination'
import { RssAutodiscovery } from '@hmn/rtl-net-ui/components/RssAutodiscovery'
import { SearchDetails } from '@hmn/rtl-net-ui/components/Search'
import { netSectionTitleSizeVariants, SectionTitle } from '@hmn/rtl-net-ui/components/SectionTitle'
import { imageDimensions } from '@hmn/rtl-net-ui/helpers'

import { BaseLayout } from '../../layouts'
import { Seo } from '..'

const months = [
    'Siječanj',
    'Veljača',
    'Ožujak',
    'Travanj',
    'Svibanj',
    'Lipanj',
    'Srpanj',
    'Kolovoz',
    'Rujan',
    'Listopad',
    'Studeni',
    'Prosinac'
]

const perPage = 50

const { halfpage1, outOfPage1, outOfPage2, outOfPage3, interstitial } = staticAds

export function Archive({ category, articles, datePickerData, navigation, slugs, year, month }) {
    const uid = useUIDSeed()
    const router = useRouter()

    const currentPage = useCurrentPageNumber({ isArchive: true })

    useEntityTypeDispatch('category-archive')

    const { messageType, messageSource } = router.query

    const { data: categoryPreview } = usePreview(category, { type: messageType, source: messageSource })

    const seoItem = useMemo(
        () => ({
            ...categoryPreview,
            title: `${categoryPreview?.title} / Arhiva, ${months[month - 1]} ${year}`,
            breadcrumb: [categoryPreview?.path?.map(pathNode => pathNode.slug)],
            sitemap: {
                href:
                    categoryPreview?.sitemap?.href &&
                    `${categoryPreview.sitemap.href}${currentPage > 1 ? `?stranica=${currentPage}` : ''}`
            }
        }),
        [categoryPreview, month, year, currentPage]
    )

    /** ***************ADS******************* */
    // @NOTE: old targeting arguments, compare with new and remove
    // const targetingArguments = useMemo(
    //     () => ({
    //         post_type: ['listing', 'archive'],
    //         nethr_category: categoryPreview?.path?.map(pathNode => pathNode.slug) || []
    //     }),
    //     [categoryPreview]
    // )
    const insertAds = useListingInsertAds({
        insertAdsConfig,
        AdSlotComponent: AdSlot,
        maxAdWidth: 750,
        ColumnComponent: Column
    })

    return (
        <BaseLayout navigationData={navigation}>
            <IsolatedUseCategoryThirdParties
                appRoot={process.env.NEXT_PUBLIC_APP_ROOT_NET}
                homepageId={process.env.NEXT_PUBLIC_HOMEPAGE_ID_NET}
                gemiusId={process.env.NEXT_PUBLIC_GEMIUS_ID_NET}
                node={category}
                nodeId={category.id}
                slugs={slugs}
                isListing
                isArchive
            />
            <RssAutodiscovery sitemap={categoryPreview?.sitemap} />
            <Seo item={seoItem} page={currentPage} listItems={articles} />
            <Row variant="archive.main">
                <Column variant="archive.listing">
                    <SectionTitle
                        title={`${categoryPreview?.title} /`}
                        archiveTitle={` ARHIVA, ${month ? `${months[month - 1]} ` : ''}${year}`}
                        section="archive"
                        sizeVariant={netSectionTitleSizeVariants['1x1']}
                        isListing
                        borderColor={categoryPreview?.extended_attributes?.category_color}
                    />
                    <SearchDetails resultCount={articles?.[0]?.totalItems} isArchive />
                    <Media lessThan="md" scaleDown>
                        <ArchiveDatePicker
                            slugs={slugs}
                            data={datePickerData?.[0]}
                            initialYear={year || ''}
                            initialMonth={month ? `/ ${month}` : ''}
                            pageTitle={categoryPreview?.title}
                        />
                    </Media>

                    {!!articles?.length &&
                        articles.map((item, index) => (
                            <Fragment key={uid(item?.id)}>
                                <Column variant="archive.listing.article">
                                    <Media lessThan="md" scaleDown>
                                        <ArticleCard
                                            item={item}
                                            imageVariation={
                                                index === 0 && currentPage < 2
                                                    ? imageRatioVariants.CUSTOM_WIDE_SCREEN
                                                    : imageRatioVariants.CUSTOM_SQUARE
                                            }
                                            variant={
                                                index === 0 && currentPage < 2
                                                    ? articleCardVariants.ALPHA
                                                    : articleCardVariants.BETA
                                            }
                                            imageWidth={
                                                index || currentPage !== 1
                                                    ? imageDimensions('listing', true)?.[1].width
                                                    : imageDimensions('listing', true)?.[0].width
                                            }
                                            imageHeight={
                                                index || currentPage !== 1
                                                    ? imageDimensions('listing', true)?.[1].height
                                                    : imageDimensions('listing', true)?.[0].height
                                            }
                                            sizeVariant={
                                                index === 0 && currentPage < 2
                                                    ? articleCardSizeVariants['2x3']
                                                    : articleCardSizeVariants['1x6']
                                            }
                                            href={item?.sitemap?.href}
                                            contentMarkerVariant={item?.type.toUpperCase()}
                                            categoryColor={categoryPreview?.extended_attributes?.category_color}
                                            imageLazyLoad={index > 2}
                                            imagePreload={index === 0}
                                        />
                                    </Media>
                                    <Media greaterThan="sm" scaleDown>
                                        <ArticleCard
                                            item={item}
                                            imageVariation={imageRatioVariants.CUSTOM_WIDE_SCREEN}
                                            variant={
                                                index === 0 && currentPage < 2
                                                    ? articleCardVariants.ALPHA
                                                    : articleCardVariants.BETA
                                            }
                                            imageWidth={
                                                index || currentPage !== 1
                                                    ? imageDimensions('listing')?.[1].width
                                                    : imageDimensions('listing')?.[0].width
                                            }
                                            imageHeight={
                                                index || currentPage !== 1
                                                    ? imageDimensions('listing')?.[1].height
                                                    : imageDimensions('listing')?.[0].height
                                            }
                                            href={item?.sitemap?.href}
                                            sizeVariant={articleCardSizeVariants['2x3']}
                                            contentMarkerVariant={item?.type.toUpperCase()}
                                            categoryColor={categoryPreview?.extended_attributes?.category_color}
                                            imageLazyLoad={index > 2}
                                            imagePreload={index === 0}
                                            isArchive
                                        />
                                    </Media>
                                </Column>
                                <ConditionalInsert insert={insertAds} currentIndex={index} />
                            </Fragment>
                        ))}

                    {articles?.[0]?.totalItems > perPage && year && month && (
                        <Row variant="archive.pagination">
                            <Pagination
                                pageTotal={(articles?.[0]?.totalItems || 0) / perPage}
                                selected={currentPage}
                                section={categoryPreview?.title}
                                paginationColor={categoryPreview?.extended_attributes?.category_color}
                                pageName={categoryPreview?.title}
                            />
                        </Row>
                    )}
                </Column>
                <Column variant="archive.aside" className="aside">
                    <Media className="Sidebar_aside" greaterThan="sm" scaleDown>
                        <ArchiveDatePicker
                            slugs={slugs}
                            data={datePickerData?.[0]}
                            initialYear={year || ''}
                            initialMonth={month ? `/ ${month}` : ''}
                            pageTitle={categoryPreview?.title}
                        />
                        <Column variant="archive.ads.floating">
                            <AdSlot
                                {...halfpage1}
                                slidingProps={{
                                    ...(halfpage1.slidingProps || {}),
                                    offsetBottom: 120
                                }}
                            />
                        </Column>
                    </Media>
                </Column>
            </Row>
            <Row variant="zeroDimensions">
                <AdSlot {...outOfPage1} />
                <AdSlot {...outOfPage2} />
                <AdSlot {...outOfPage3} />
                <AdSlot {...staticAds.anchor} />
                <AdSlot {...interstitial} />
            </Row>
        </BaseLayout>
    )
}

Archive.propTypes = {
    category: PropTypes.shape({
        id: PropTypes.string.isRequired
    }).isRequired,
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            totalItems: PropTypes.number.isRequired
        })
    ).isRequired,
    datePickerData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    navigation: PropTypes.shape({}).isRequired,
    slugs: PropTypes.arrayOf(PropTypes.string).isRequired,
    year: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired
}
