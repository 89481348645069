import React, { useEffect } from 'react'

/**
 * # Warning: Causes a component rerender on client side
 * uses useState and useEffect to set isMounted to true when code is ran on client side
 */
export function useIsClientMounted() {
    const [isMounted, setIsMounted] = React.useState(false)
    useEffect(() => {
        setIsMounted(true)
    }, [])
    return isMounted
}
