import React, { useMemo } from 'react'

import { shouldRenderOnDay } from '@hmn/rtl-web-core/components/RenderOnDay/RenderOnDay.helpers'

import AdSlot from '@hmn/rtl-net-ui/components/Ad/NetSlot.component'

export const admiralhardcodedSlot = {
    position: 'AdmiralHardcoded'
    // minimumFixedHeightMobile: 380,
    // minimumFixedHeightDesktop: 350
}

const isEnabledFlag = false

export function useAdmiralInsert(enabled = true) {
    const insertConfig = useMemo(
        () =>
            isEnabledFlag &&
            enabled &&
            shouldRenderOnDay({
                // @TODO: check these dates, maybe refactor shouldRenderOnDay to add AND/OR logic
                months: ['june', 'july', 'august', 'september', 'october', 'november', 'december'],
                years: ['2023']
            }) && {
                isStatic: true,
                position: -2,
                element: <AdSlot {...admiralhardcodedSlot} />
            },
        [enabled]
    )
    return insertConfig
}
