import { toRem } from '../../helpers'

const style = ({ theme, open, categoryColor }) => ({
    display: 'none',
    position: 'fixed',
    zIndex: theme.zIndex.modal,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    ...(open && {
        display: 'block'
    }),

    // Modal Inner Stuff:
    '.modal': {
        '&__backdrop': {
            zIndex: 10,
            position: 'fixed',
            right: 0,
            bottom: 0,
            top: 0,
            left: 0,
            backgroundColor: theme.colors.black
        },
        '&__inner': {
            position: 'relative',
            zIndex: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            minHeight: '100vh'
        }
    },

    // Gallery Stuff:
    '.gallery': {
        // Gallery Header Stuff:
        '&__header': {
            display: 'flex',
            flex: '0 0 auto',
            justifyContent: 'space-between',
            width: '100%',
            background: '#000',
            color: '#fff',
            padding: '8px',

            [theme.breakpoints.up('md')]: {
                padding: '20px'
            }
        },

        '&__logo': {
            display: 'inline-block',
            height: '25px',
            flex: 1,

            [theme.breakpoints.up('md')]: {
                height: '30px'
            },

            '&-img': {
                width: 'auto',
                height: '100%',
                marginBottom: '10px',

                [theme.breakpoints.up('md')]: {
                    fontSize: '2rem',
                    marginBottom: '5px'
                }
            },

            '.colorme': {
                fill: '#F15B24'
            },

            '.colortxt': {
                fill: '#fff'
            }
        },

        '&__close, &__header_share': {
            flex: '0 0 40px',
            padding: 0,
            border: 0,
            backgroundColor: 'transparent',
            color: '#fff',
            fontSize: '24px',
            cursor: 'pointer',
            lineHeight: 0,
            textAlign: 'right',

            [theme.breakpoints.up('md')]: {
                lineHeight: 1,
                textAlign: 'center'
            }
        },

        '&__close': {
            marginTop: '2px',

            '> span > svg': {
                height: '16px',

                [theme.breakpoints.up('md')]: {
                    height: '24px'
                },

                path: {
                    fill: '#fff'
                }
            }
        },

        '&__header_share': {
            position: 'relative',
            fontSize: '24px',

            [theme.breakpoints.up('md')]: {
                display: 'none'
            },

            '> span > svg': {
                height: '24px',
                marginTop: '5px'
            },

            '> ul': {
                position: 'absolute',
                right: '-10px',
                lineHeight: 1
            },

            '> ul.is_floating': {
                position: 'absolute',
                top: '40px',
                right: 0,
                padding: '10px 10px 4px',
                lineHeight: 1,
                backgroundColor: '#111',
                border: '1px solid rgba(255, 255, 255, 0.3)',
                borderRadius: '4px',
                boxShadow: '0 0 6px #000'
            }
        },

        // Gallery Swiper Stuff:
        '&__main': {
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            color: '#fff',

            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                alignItems: 'stretch',
                justifyContent: 'stretch'
            }
        },

        '&__share': {
            display: 'none',

            [theme.breakpoints.up('md')]: {
                flex: '0 0 100px',
                order: 0,
                display: 'block'
            },

            ul: {
                margin: '8px 0 0'
            },

            li: {
                textAlign: 'center',

                [theme.breakpoints.up('md')]: {
                    display: 'block'
                }
            }
        },

        '&__swiper': {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            maxWidth: '100vw',
            padding: '10px',
            background: theme.colors.grey900,

            [theme.breakpoints.up('md')]: {
                maxWidth: 'calc(100vw - 440px)'
            },

            '.title': {
                marginBottom: '15px',
                color: '#fff',
                fontSize: '1.5rem',

                [theme.breakpoints.up('md')]: {
                    fontSize: '2rem'
                }
            },

            '.title_subtitle': {
                color: categoryColor
            }
        },

        '&__marketing': {
            display: 'block',
            padding: '0',
            textAlign: 'center',

            '> div': {
                display: 'inline-block',
                maxWidth: '350px',
                maxHeight: '100px',
                textAlign: 'center',
                marginBottom: 0
            },

            [theme.breakpoints.up('md')]: {
                flex: '0 0 340px',
                display: 'block',
                padding: '20px 0 0',
                textAlign: 'center',

                '> div': {
                    display: 'inline-block',
                    width: '300px',
                    height: '600px'
                }
            }
        }
    },

    // Swiper Stuff:
    '.swiper': {
        position: 'absolute',
        top: 0,
        right: '-10px',
        bottom: 0,
        left: '-10px',
        paddingBottom: '30px',

        [theme.breakpoints.up('md')]: {
            // position: 'relative'
        },

        '&__container': {
            flex: 1,
            position: 'relative'
        },

        '&-slide': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '.gallery__slide > div, .gallery__image': {
                // we don't need this <div> and <picture> wrapper
                display: 'contents'
            },

            '.gallery__slide': {
                position: 'relative',
                top: 'auto',
                right: 'auto',
                bottom: 'auto',
                left: 'auto',
                display: 'inline-block',
                width: 'auto',
                height: '100%',

                '&.is_landscape': {
                    height: 'auto',
                    width: '100%'
                },

                '&_source, &_description': {
                    position: 'absolute',
                    inset: '0 0 auto auto',

                    display: 'inline-block',
                    padding: '5px 10px',
                    backgroundColor: '#080808',
                    color: '#fff',
                    fontSize: toRem(11),
                    opacity: 0.7
                },

                '&_description': {
                    inset: 'auto auto 0 0',
                    // INFO: Progressive Enhancement
                    // not supported in all browsers (https://caniuse.com/?search=text%20wrap%20pretty)
                    textWrap: 'pretty'
                }
            },

            img: {
                position: 'static',
                height: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'center'
            },
            span: {
                position: 'absolute',
                bottom: '10px',
                right: '10px',
                padding: '5px 10px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        },

        '[data-final-aspect="is_landscape"] .gallery__slide': {
            height: 'auto',
            width: '100%'
        },

        '&-button-next, &-button-prev': {
            '--swiper-navigation-size': '72px',

            right: 0,
            left: 'auto',
            width: '36px',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',

            '&::before, &::after': {
                fontSize: '1.5rem',
                color: '#fff'
            }
        },
        '&-button-prev': {
            right: 'auto',
            left: 0
        },

        '&-pagination': {
            fontFamily: 'proxima-nova,ArialToProximaNova,sans-serif',
            fontSize: '1rem',
            fontWeight: 700,
            textShadow: '-1px 0 2px #080808, 0 1px 2px #080808, 1px 0 2px #080808, 0 -1px 2px #080808',

            '&-current': {
                color: categoryColor
            }
        }
    }
})

export default style
