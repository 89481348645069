import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useAmp } from 'next/amp'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'

import { AmpAnalytics } from '../components/Amp'
import isValidDate from '../helpers/isValidDate'
import useCurrentPageNumber from './useCurrentPageNumber'
import { IsolatedUseEntityGtmEvents } from './useEntityGtmEvents'
import { IsolatedUseGemius } from './useGemius'
import useUpScore from './useUpScore'

dayjs.extend(utc)
dayjs.extend(tz)
const mapEntityTypeToUpscoreObjectType = entityType => {
    switch (entityType) {
        case 'article':
        case 'zodiac':
        case 'hunk-of-the-day':
            return 'article'
        case 'video':
            return 'video'
        default:
            return entityType
    }
}

const parseUpscoreNextPageUrl = (entity, entityType, router, currentPage, articleType, appRoot, childId) => {
    if (entityType === 'video') {
        return undefined
    }
    if (articleType === 'Prev Next article' && !router?.query?.fullArticle) {
        return `${appRoot}${entity?.sitemap?.href}?stranica=${currentPage}`
    }
    return `${appRoot}${entity?.sitemap?.href}${childId ? `/${childId}` : ''}`
}

export function IsolatedEntityThirdParties({
    entity,
    articleType,
    isPrevNext,
    defaultGemiusId,
    appRoot,
    appName,
    ampGtmId,
    entityType,
    childId,
    gemiusDiff,
    gallerySlideIndex
}) {
    const currentPage = useCurrentPageNumber()
    const router = useRouter()

    const isAmp = useAmp()

    const upScoreParams = useMemo(() => {
        const isAuthor = entityType === 'author'
        if (isAuthor) {
            const { slug, pageNumber } = router.query
            return {
                id: entity.id,
                objectType: 'misc',
                category: 'autori',
                subCategory: slug,
                nextPageUrl: pageNumber ? `${process.env.NEXT_PUBLIC_APP_ROOT_NET}/autori/${slug}/${pageNumber}` : ''
            }
        }

        const isArticleType = ['article', 'zodiac', 'column'].includes(entityType)
        const hasAuthor = ['article', 'column', 'zodiac', 'video', 'gallery'].includes(entityType)

        return {
            id: entity.id,
            objectType: mapEntityTypeToUpscoreObjectType(entityType),
            category: entity?.taxons?.sitemap?.[0]?.path?.[0]?.title,
            subCategory: entity?.taxons?.sitemap?.[0]?.path?.[1]?.title,
            nextPageUrl: parseUpscoreNextPageUrl(
                entity,
                entityType,
                router,
                currentPage,
                articleType,
                appRoot,
                childId
            ),
            gallerySlide: gallerySlideIndex,
            author: (hasAuthor && (entity?.creator?.display_name || appName)) || undefined,
            pubdate: isValidDate(entity?.published_at) ? dayjs(entity.published_at).toISOString() : undefined,
            isArticle: isArticleType || undefined
        }
    }, [entity, router?.query, childId, currentPage])
    const upScoreData = useUpScore(upScoreParams)
    const gemiusId = entity?.sitemap?.node?.gemiusId || defaultGemiusId

    if (isAmp) {
        if (!ampGtmId) {
            return null
        }
        return (
            <AmpAnalytics
                // dotmetricsId="2596"
                gtmId={ampGtmId}
                upScoreData={upScoreData}
                gemiusId={gemiusId}
            />
        )
    }

    return (
        <>
            <IsolatedUseEntityGtmEvents
                tags={entity?.tags || []}
                entity={entity}
                articleType={articleType}
                entityType={entityType}
                mainTitle={entity.title}
                isPrevNext={isPrevNext}
                currentPage={currentPage}
                appName={appName}
                childId={childId}
            />
            <IsolatedUseGemius gemiusId={gemiusId} diff={gemiusDiff || childId || entity?.id} />
        </>
    )
}

IsolatedEntityThirdParties.propTypes = {
    entity: PropTypes.shape({
        id: PropTypes.string.isRequired,
        taxons: PropTypes.shape({
            sitemap: PropTypes.arrayOf(
                PropTypes.shape({
                    path: PropTypes.arrayOf(
                        PropTypes.shape({
                            title: PropTypes.string.isRequired
                        })
                    )
                })
            ),
            authors: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string.isRequired
                })
            )
        }).isRequired,
        sitemap: PropTypes.shape({
            href: PropTypes.string.isRequired,
            node: PropTypes.shape({
                gemiusId: PropTypes.string
            })
        }).isRequired,
        creator: PropTypes.shape({
            display_name: PropTypes.string
        }).isRequired,
        published_at: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string
            })
        ),
        extended_attributes: PropTypes.shape({
            author: PropTypes.string
        })
    }).isRequired,
    articleType: PropTypes.string,
    isPrevNext: PropTypes.bool,
    defaultGemiusId: PropTypes.string.isRequired,
    appRoot: PropTypes.string.isRequired,
    appName: PropTypes.string.isRequired,
    ampGtmId: PropTypes.string,
    entityType: PropTypes.oneOf(['author', 'article', 'hunk-of-the-day', 'zodiac', 'gallery', 'video']).isRequired,
    childId: PropTypes.string,
    gemiusDiff: PropTypes.string,
    gallerySlideIndex: PropTypes.number
}

IsolatedEntityThirdParties.defaultProps = {
    articleType: '',
    isPrevNext: false,
    childId: undefined,
    ampGtmId: undefined,
    gemiusDiff: undefined,
    gallerySlideIndex: undefined
}
