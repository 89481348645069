import { toRems } from '@hmn/rtl-web-core/helpers/theme'

import { toRem } from '../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, areaXs, areaMd, columnsXs, columnsMd, blockColor, minHeight, isAmp, separator }) => {
    const separatorBorder = `1px solid ${theme.colors?.separator || theme.colors.devWarning}`
    const config = {
        xs: {
            gridTemplateColumns: columnsXs,
            gridTemplateAreas: areaXs,
            gridRowGap: toRem(20),
            gridColumnGap: toRem(15),
            minHeight: (minHeight?.xs && toRem(minHeight.xs)) || 'initial'
        },
        md: {
            gridTemplateColumns: columnsMd,
            gridTemplateAreas: areaMd,
            gridRowGap: toRem(30),
            gridColumnGap: toRem(30),
            minHeight: (minHeight?.md && toRem(minHeight.md)) || 'initial'
        }
    }

    return {
        position: 'relative',
        marginBottom: toRem(20),
        ...(separator && {
            borderTop: separatorBorder,
            borderBottom: separatorBorder,
            padding: toRems([0, 0, 10])
        }),
        ...(!isAmp && {
            [theme.breakpoints.up('md')]: {
                padding: toRem(15),

                ...(separator && {
                    padding: toRems([0, 0, 15])
                })
            }
        }),
        '& .netCardsBlock_inner': {
            position: 'relative',
            backgroundColor: theme.colors.background.default
        },
        '& .netCardsBlock_list': {
            marginTop: toRem(6),
            display: 'grid',
            gridTemplateColumns: config?.xs.gridTemplateColumns,
            gridColumnGap: config?.xs.gridColumnGap,
            gridRowGap: config?.xs.gridRowGap,
            gridTemplateAreas: config?.xs.gridTemplateAreas,
            alignItems: 'flex-start',

            '.end-article &': {
                gridRowGap: '8px'
            },

            // minHeight: config?.xs.minHeight,
            ...(!isAmp && {
                [theme.breakpoints.up('md')]: {
                    // minHeight: config?.md.minHeight,
                    marginTop: toRem(20),
                    gridTemplateColumns: config?.md.gridTemplateColumns,
                    gridColumnGap: config?.md.gridColumnGap,
                    gridRowGap: config?.md.gridRowGap,
                    alignItems: config?.md.alignItems,
                    gridTemplateAreas: config?.md.gridTemplateAreas,

                    '.end-article &': {
                        gridRowGap: '16px'
                    }
                }
            })
        },
        ...(!isAmp && {
            [theme.breakpoints.down('sm')]: {
                '& .sectionTitle_wrapper': {
                    marginBottom: toRem(20)
                }
            }
        }),
        '& .netCardsBlock_item': {
            '&:nth-of-type(1)': {
                gridArea: 'a'
                // [theme.breakpoints.up('md')]: {
                //     '.cardContent': {
                //         fontSize: `${toRem(24)} !important`
                //     }
                // }
            },
            '&:nth-of-type(2)': {
                gridArea: 'b'
                // [theme.breakpoints.up('md')]: {
                //     '.cardContent': {
                //         fontSize: `${toRem(24)} !important`
                //     }
                // }
            },
            '&:nth-of-type(3)': {
                gridArea: 'c'
                // [theme.breakpoints.up('md')]: {
                //     '.cardImage': {
                //         height: toRem(214),
                //         '> div:first-of-type': {
                //             paddingBottom: toRem(214)
                //         }
                //     }
                // }
            },
            '&:nth-of-type(4)': {
                gridArea: 'd'
            },
            '&:nth-of-type(5)': {
                gridArea: 'e'
            },
            '&:nth-of-type(6)': {
                gridArea: 'f'
            }
        },
        ...(blockColor && {
            '& .cardContent_subtitle': {
                color: `${blockColor}` // !important`
            },
            '& .cardContent_separator': {
                color: blockColor
            },
            '& .sectionTitle_wrapper:before': {
                background: blockColor
            },
            '& .sectionTitle_wrapper:after': {
                background: blockColor
            },
            '& .sectionTitle_title:hover': {
                color: blockColor
            }
        })
    }
}

export default style
