/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useUIDSeed } from 'react-uid'

import { Column } from '@hmn/rtl-web-core/components/layout'
import { Media } from '@hmn/rtl-web-core/components/Media'

import { articleCardContentMarkerVariants } from '@hmn/rtl-net-ui/components/Article/components'
import ArticleCard from '@hmn/rtl-net-ui/components/Article/components/Card/Card.component'
import CardsBlock from '@hmn/rtl-net-ui/components/CardsBlock/CardsBlock.component'

const articleCardContentMarkerVariant = type => {
    switch (type.toUpperCase()) {
        case 'ARTICLE':
            return articleCardContentMarkerVariants.ARTICLE
        case 'GALLERY':
            return articleCardContentMarkerVariants.GALLERY
        case 'VIDEO':
            return articleCardContentMarkerVariants.VIDEO
        default:
            return articleCardContentMarkerVariants.NONE
    }
}

const gtmData = {
    eventCategory: 'Clanak',
    eventAction: 'Below Mozda ce te zanimati',
    eventLabel: 'Main'
}

function YouMightBeInterestedBox({ excludeId = undefined, title = 'Možda će te zanimati' }) {
    const uid = useUIDSeed()

    const [data, setData] = useState([])

    useEffect(() => {
        const searchParams = new URLSearchParams([['limit', 5]])

        if (excludeId) {
            searchParams.append('exclude', excludeId)
        }

        fetch(`/api/you-might-be-interested?${searchParams.toString()}`).then(async response => {
            if (!response.ok) {
                // eslint-disable-next-line no-console
                console.error(response.body)
                return
            }

            const entities = await response.json()

            setData(entities)
        })
    }, [excludeId])

    if (!data?.length) {
        return null
    }

    return (
        <Column id="might_be_interested">
            <Media greaterThan="sm" scaleDown>
                <CardsBlock
                    title={title}
                    areaXs={`"a" "b" "c" "d" "e" "f"`}
                    areaMd={`"a b" "a c" "a d" "a e"`}
                    columnsXs="1fr"
                    columnsMd="1fr 1fr"
                    count={5}
                    config={[
                        {
                            variant: 'ALPHA',
                            size: '1x5',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 350,
                            imageWidth: 350
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        }
                    ]}>
                    {data.map((item, index) => (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                            gtmData={gtmData}
                        />
                    ))}
                </CardsBlock>
            </Media>
            <Media lessThan="md" scaleDown>
                <CardsBlock
                    title={title}
                    areaXs={`"a" "b" "c" "d" "e" "f"`}
                    areaMd={`"a b" "a c" "a d" "a e"`}
                    columnsXs="1fr"
                    columnsMd="1fr 1fr"
                    count={5}
                    config={[
                        {
                            variant: 'ALPHA',
                            size: '1x5',
                            image: 'CUSTOM_WIDE_SCREEN',
                            imageHeight: 350,
                            imageWidth: 200
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        },
                        {
                            variant: 'BETA',
                            size: '1x6',
                            image: 'CUSTOM_SQUARE',
                            imageHeight: 100,
                            imageWidth: 100
                        }
                    ]}>
                    {data.map((item, index) => (
                        <ArticleCard
                            item={item}
                            key={uid(item?.id || index)}
                            contentMarkerVariant={articleCardContentMarkerVariant(item?.type)}
                            gtmData={gtmData}
                        />
                    ))}
                </CardsBlock>
            </Media>
        </Column>
    )
}

export default YouMightBeInterestedBox
