/* eslint-disable max-len */
import Head from 'next/head'
import PropTypes from 'prop-types'

import { useIsCroatiaCountryCode } from '../../context/CountryCode.context'
import { useIsClientMounted } from '../../hooks/functional/useIsClientMounted'
import useDeviceType from '../../hooks/layout/useDeviceType'

export function MonetizeAd({ userId, siteId, widgetId, widgetType, isDesktop, isMobile }) {
    const isClientMounted = useIsClientMounted()
    const [, isDesktopDevice, isMobileDevice] = useDeviceType()
    const canRun = (isDesktopDevice && isDesktop) || (isMobileDevice && isMobile) || (!isDesktop && !isMobile)
    const isHR = useIsCroatiaCountryCode()

    if ((!canRun && isClientMounted) || !isHR) {
        return null
    }
    return (
        <>
            {isClientMounted && canRun && (
                <Head>
                    <script
                        id="monetizeAd"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                            __html: `
var mpn_wi = {
    userId: ${userId},
    siteId: ${siteId},
    widgetId: ${widgetId},
    widgetType: ${widgetType}
};
if (void 0 === mpn_ref) var mpn_ref = [mpn_wi];
else mpn_ref.push(mpn_wi);
var mpn_sid = document.getElementById('monadplugscript');
if (!mpn_sid) {
    var mpn_dt = new Date,
        mpn_ns = document.createElement('script');
    mpn_ns.id = 'monadplugscript';
    mpn_ns.type = 'text/javascript';
    mpn_ns.defer = !0;
    mpn_ns.src = '//cdn.monadplug.com/format/native/js/hood.js?v=' + mpn_dt.getYear() + mpn_dt.getMonth() + mpn_dt.getUTCDate() + mpn_dt.getUTCHours();
    var pmn_os = document.getElementsByTagName('script')[0];
    pmn_os.parentNode.insertBefore(mpn_ns, pmn_os)
}`
                        }}
                    />
                </Head>
            )}
            <div id={`${userId}-${siteId}-${widgetId}`} />
        </>
    )
}

MonetizeAd.propTypes = {
    userId: PropTypes.number,
    siteId: PropTypes.number,
    widgetId: PropTypes.number,
    widgetType: PropTypes.number,
    isDesktop: PropTypes.bool,
    isMobile: PropTypes.bool
}

MonetizeAd.defaultProps = {
    userId: 44280,
    siteId: 106241,
    widgetId: 107586,
    widgetType: 0,
    isDesktop: false,
    isMobile: false
}
