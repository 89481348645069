/* eslint-disable react/prop-types */
import React, { useMemo } from 'react'

import { Column } from '@hmn/rtl-web-core/components/layout'
import { useBreakpoints } from '@hmn/rtl-web-core/hooks'

import ArticleGalleryHead from '@hmn/rtl-net-ui/components/Article/components/GalleryHead/GalleryHead.net.component'
import ArticleHead from '@hmn/rtl-net-ui/components/Article/components/Head/Head.net.component'
import ArticleVideoHead from '@hmn/rtl-net-ui/components/Article/components/VideoHead/VideoHead.net.component'
import { Button, buttonSizes, netButtonVariants } from '@hmn/rtl-net-ui/components/Button'
import { Columnist } from '@hmn/rtl-net-ui/components/Columnist'
import { ArrowSmallIcon, Icon } from '@hmn/rtl-net-ui/components/Icon'

import Seo from './NetSeo.component'

function CombinedArticleHead({
    article,
    columnVariant = 'article.head.content',
    columnist: columnistInput,
    isHunk = false,
    isZodiac = false,
    isColumn = false,
    headVariant,
    inPictureAdConfig,
    articleVideo,
    articleGallery
}) {
    const [isDesktop] = useBreakpoints('md')
    /** ********************* COLUMNIST INSERT ******************** */

    const columnist = useMemo(
        () =>
            (columnistInput && (
                <Columnist
                    author={columnistInput}
                    title=""
                    isColumnist={isColumn}
                    button={
                        <Button
                            className="cta_button"
                            variant={netButtonVariants.BETA}
                            size={buttonSizes.SMALL}
                            iconComponent={
                                <Icon icon={ArrowSmallIcon} style={{ width: 18, height: 18 }} viewBox="0 0 24 24" />
                            }>
                            {isColumn ? 'Sve kolumne' : 'Svi članci'} autora
                        </Button>
                    }
                />
            )) ||
            null,
        [columnistInput, isColumn]
    )

    function getHeadVariant() {
        const commonTitle =
            isDesktop || !article?.extended_attributes?.mobile_title
                ? article?.title
                : article?.extended_attributes?.mobile_title

        const commonAuthor = article?.taxons?.authors?.[0]?.title || article?.extended_attributes?.author || 'net.hr'
        const commonAuthors = article?.taxons?.authors || article?.extended_attributes?.author || [{ title: 'net.hr' }]

        const commonProps = {
            title: commonTitle,
            subtitle: article?.subtitle,
            lead: article?.lead,
            date: article?.published_at || article?.created_at,
            author: commonAuthor,
            authors: commonAuthors,
            authorSlug: article?.taxons?.authors?.[0]?.slug,
            categoryColor: article?.sitemap?.node?.categoryColor,

            columnist
        }

        if (articleVideo) {
            const videoUrl = article?.extended_attributes?.header_video_url
            return (
                <ArticleVideoHead
                    {...commonProps}
                    label={article?.sitemap?.node?.title}
                    labelLink={article?.sitemap?.node?.href}
                    halfGreyBackground
                    rtlUrl={videoUrl}
                    videoElastic={articleVideo}
                    SeoComponent={Seo}
                />
            )
        }

        if (articleGallery) {
            return (
                <ArticleGalleryHead
                    {...commonProps}
                    image={articleGallery?.image}
                    gallery={articleGallery}
                    imageUpdatedAt={article?.image?.updated_at}
                    photoAuthor={article?.image?.source}
                    columnist={columnist}
                    isHunk={isHunk}
                    isZodiac={isZodiac}
                    variant={headVariant}
                    inPictureAdConfig={inPictureAdConfig}
                />
            )
        }

        return (
            <ArticleHead
                {...commonProps}
                image={article?.image}
                noHeroImage={article?.sitemap?.node?.href === '/webcafe/vic-dana'}
                imageUpdatedAt={article?.image?.updated_at}
                photoAuthor={article?.image?.source}
                isHunk={isHunk}
                isZodiac={isZodiac}
                variant={headVariant}
                inPictureAdConfig={inPictureAdConfig}
            />
        )
    }

    /** ********************* RENDER HEAD VARIANT ******************** */
    return (
        <Column variant={columnVariant} id="article_head">
            {getHeadVariant()}
        </Column>
    )
}
export default CombinedArticleHead
