import { withErrorBoundary } from '@hmn/rtl-web-core/components/ErrorBoundary/ErrorBoundary.component'

// *** DO NOT REMOVE ***
// INFO: maps used to map the category to the linker widget id
// Leave this maps here for reference and further AdNow implementation
//
// const linkerCategoryMap = {
//     'e-commerce': '529',
//     vijesti: '384',
//     danas: '384',
//     sport: '386',
//     magazin: '392',
//     hot: '390',
//     webcafe: '388',
//     overkloking: '384',
//     'cura-dana': '388',
//     'vic-dana': '392',
//     gallery: '402',
//     izdvojeno: '385',
//     footer: '236',
//     default: '384'
// }

// const altLinkerCategoryMap = {
//     vijesti: '702',
//     webcafe: '703',
//     hot: '704',
//     sport: '706',
//     magazin: '705'
// }

// const widgetScript = `
//     <div id="SC_TBlock_883685"></div>
//     <script type="text/javascript">
//     (sc_adv_out = window.sc_adv_out || []).push({
//         id: "883685",
//         domain: "n.nnowa.com",
//         no_div: false
//     });
//     </script>
//     <script async src="//st-n.nnowa.com/js/a.js"></script>
//     <script src="/utils/iframeResizer.contentWindow.min.js"></script>
// `

function AdNow() {
    // @TODO: Remove this once they behave
    return null

    // const iframeRef = useRef(null)
    // const { thirdPartyEnabled } = useEnvironment()
    // const isHR = useIsCroatiaCountryCode()
    // if (!thirdPartyEnabled || !isHR) {
    //     return null
    // }
    // return (
    //     <>
    //         <InView
    //             delay={600}
    //             threshold={0.7}
    //             triggerOnce
    //             initialInView={false}
    //             onChange={inView => {
    //                 if (inView && iframeRef.current) {
    //                     setTimeout(() => {
    //                         iframeRef.current.iFrameResizer?.resize()
    //                     }, 300)
    //                 }
    //             }}
    //         />
    //         <IframeResizer
    //             forwardRef={iframeRef}
    //             srcDoc={widgetScript}
    //             checkOrigin={false}
    //             heightCalculationMethod="bodyOffset"
    //             style={{ width: '1px', minWidth: '100%', border: 'none' }}
    //         />
    //     </>
    // )
}

export default withErrorBoundary(AdNow, {
    FallbackComponent: () => null,
    onError(error, componentStack) {
        // eslint-disable-next-line no-console
        console.error('[AdNow]: ', error, componentStack)
    }
})
