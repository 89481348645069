import { useEffect, useMemo, useRef } from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { useAmp } from 'next/amp'

import { ClientNavigationState, useClientNavigationState } from '../context/clientNavigation'
import createIsolatedHook from '../helpers/createIsolatedHook'
import useGTM, { resetGTMEvents } from './useGTM'
import useGtmArticleTags from './useGtmArticleTags'

dayjs.extend(utc)
dayjs.extend(tz)

const parseContentType = (articleType, entityType) => {
    switch (entityType) {
        case 'author':
            return 'author pages'
        case 'column':
            return 'Column'
        case 'zodiac':
            return 'Horoskop'
        case 'gallery':
            return 'Gallery'
        case 'video':
            return 'Video'
        default:
            return articleType || entityType
    }
}

const hasGtmMetadataEvent = entityType => {
    switch (entityType) {
        case 'author':
            return false
        default:
            return true
    }
}

export function useEntityGtmEvents({
    tags,
    entity,
    articleType,
    mainTitle,
    isPrevNext,
    currentPage,
    appName,
    entityType,
    childId
}) {
    const isAmp = useAmp()
    const { sendDataToGTM } = useGTM()
    const pageTags = useGtmArticleTags(tags)
    const authorName = entity?.taxons?.authors?.[0]?.title || entity?.extended_attributes?.author
    const navigationState = useClientNavigationState()

    const currentEntityKey = useMemo(
        () => `${entityType}-${entity?.id}-${currentPage || 1}-${childId || 'prime'}`,
        [entityType, entity?.id, currentPage, childId]
    )

    useEffect(() => {
        if (navigationState !== ClientNavigationState.READY) {
            return
        }
        const isAuthor = entityType === 'author'

        const gtmDataGeneralInfo = isAuthor
            ? {
                  event: 'pageView',
                  userType: 'not logged in',
                  pageType: 'author pages',
                  contentID: entity?.id,
                  contentTitle: mainTitle,
                  contentAuthor: '',
                  contentCreator: ''
              }
            : {
                  event: 'gtm.metadata',
                  userType: 'not logged in',
                  pageType: isAmp ? 'AMP pages' : 'article',
                  contentID: entity?.id,
                  contentTitle: mainTitle
              }
        sendDataToGTM(gtmDataGeneralInfo)
    }, [entityType, entity.id, isAmp, mainTitle, sendDataToGTM, navigationState])

    useEffect(() => {
        if (navigationState !== ClientNavigationState.READY) {
            return
        }
        const hasEntityMetadataEvent = hasGtmMetadataEvent(entityType)
        const entityDate = dayjs(entity?.published_at)?.utc?.().tz('Europe/Zagreb').format('D.M.YYYY.')
        const gtmDataMetadataInfo = {
            event: 'gtm.metadata',
            contentAuthor: authorName,
            contentCreator: entity?.creator?.display_name || appName,
            contentPublishingDate: entityDate,
            contentType: parseContentType(articleType, entityType),
            pageTags
        }
        if (hasEntityMetadataEvent) {
            sendDataToGTM(gtmDataMetadataInfo)
        }
    }, [
        entityType,
        articleType,
        entity?.creator?.display_name,
        entity?.published_at,
        authorName,
        sendDataToGTM,
        pageTags,
        appName,
        navigationState
    ])

    const gtmDataVirtualInfo = useMemo(
        () => ({
            event: 'VirtualPageView',
            virtualPageURL: `${entityType === 'author' ? 'autori/' : ''}${entity?.sitemap?.href}`,
            virtualPageTitle: mainTitle
        }),
        [entity?.sitemap?.href, entityType, mainTitle]
    )

    const lastPageNumberRef = useRef(null)

    const firedVirtualpageViewRef = useRef(null)
    useEffect(() => {
        if (navigationState !== ClientNavigationState.READY || firedVirtualpageViewRef.current === currentEntityKey) {
            return
        }
        // @Note: This is a hack to reset VirtualPageEvent on prev/next
        if (isPrevNext) {
            if (lastPageNumberRef.current === currentPage) {
                return
            }
            lastPageNumberRef.current = currentPage
            resetGTMEvents(gtmDataVirtualInfo)
        }
        if (gtmDataVirtualInfo.virtualPageURL) {
            firedVirtualpageViewRef.current = currentEntityKey
            sendDataToGTM(gtmDataVirtualInfo, undefined, true)
        }
    }, [entity.id, sendDataToGTM, gtmDataVirtualInfo, currentPage, isPrevNext, navigationState, currentEntityKey])
}
export const IsolatedUseEntityGtmEvents = createIsolatedHook(useEntityGtmEvents)
