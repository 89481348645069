// @TODO: Adjust container dimensions when problem with image size is fixed
import { toRem } from '../../../../helpers/theme'

/* eslint-disable quote-props, no-dupe-keys, max-len */
const style = ({ theme, isTitle, isSSR, isActive }) => {
    const config = {
        sm: {
            slide: {
                position: 'absolute',
                right: 0,
                active: {
                    fontSize: toRem(18),
                    top: toRem(2)
                }
            }
        },
        md: {
            slide: {
                active: {
                    fontSize: toRem(32),
                    top: toRem(-6)
                }
            }
        }
    }

    return {
        background: theme.colors.background.default,
        // height: 'auto',
        display: isSSR && !isActive ? 'none' : 'block',
        '.galleryItemNet': {
            '&_wrapper': {
                display: 'flex',
                flexWrap: 'flex-reverse',
                position: 'relative',
                // height: '100%',
                pointerEvents: 'initial',
                '& .ampImage': {
                    height: '100%',
                    '&_wrapper': {
                        height: '100%'
                    },
                    img: {
                        objectFit: 'contain'
                    }
                }
            },
            '&_footer': {
                position: 'relative',
                paddingTop: isTitle ? toRem(10) : toRem(30),
                paddingBottom: toRem(20),
                width: 'calc(100% - 30px)',
                margin: '0 auto 10px',
                [theme.breakpoints.up('md')]: {
                    width: '100%',
                    paddingTop: toRem(10),
                    display: 'flex'
                },
                borderBottom: `1px solid ${theme.colors.border.darker}`
            },
            '&_desc': {
                p: {
                    lineHeight: 1.4,
                    fontSize: toRem(14),
                    margin: 0,
                    padding: 0
                },
                [theme.breakpoints.up('md')]: {
                    width: '86%'
                },

                '&:empty': {
                    minHeight: '10px'
                }
            },
            '&_title': {
                '& p': {
                    marginTop: toRem(15),
                    fontSize: toRem(14),
                    lineHeight: 1.4,
                    color: theme.colors.text.default,
                    fontWeight: 700,
                    wordBreak: 'break-all'
                }
            },
            '&_intro': {
                // @NOTE this block is temporary while title is disabled
                /** ********************START***************** */
                marginTop: toRem(20),
                [theme.breakpoints.up('md')]: {
                    marginTop: toRem(20)
                },
                /** ********************END***************** */
                '& p': {
                    fontSize: toRem(14),
                    fontWeight: 400,
                    lineHeight: 1.4,
                    color: theme.colors.text.default
                }
            },

            '&_slide': {
                [theme.breakpoints.up('md')]: {
                    width: isTitle ? '14%' : '100%',
                    marginTop: toRem(10),
                    display: 'flex',
                    justifyContent: 'flex-end'
                },
                [theme.breakpoints.down('md')]: {
                    position: 'absolute',
                    marginTop: toRem(10),
                    top: toRem(-5),
                    right: 0
                },
                '&_total': {
                    color: theme.colors.text.default,
                    fontSize: toRem(14),
                    lineHeight: 1.2,
                    fontWeight: 900,
                    marginLeft: toRem(3)
                },
                '&_active': {
                    color: theme.colors.text.accent,
                    position: 'relative',
                    lineHeight: 1.2,
                    fontWeight: 900,
                    fontSize: config.sm.slide.active.fontSize,
                    top: config.sm.slide.active.top,
                    [theme.breakpoints.up('md')]: {
                        fontSize: config.md.slide.active.fontSize,
                        top: config.md.slide.active.top
                    }
                }
            }
        }
    }
}

export default style
