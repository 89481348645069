import dayjs from 'dayjs'

import { nameIndexMap } from './RenderOnDay.constants'

export const checkCurrentDate = (current, checks = []) => checks.some(c => c === current)
export const checkDateProp = prop => prop && prop.length > 0

export const parseDateInput = inputValue => {
    if (!inputValue) {
        return undefined
    }
    if (!Array.isArray(inputValue)) {
        return parseDateInput([inputValue])
    }
    return inputValue
        .map(val => {
            if (val === null || typeof val === 'undefined') {
                return null
            }
            if (typeof val === 'number') {
                return val
            }
            if (typeof val === 'string') {
                const trimmed = val.trim()
                if (typeof nameIndexMap[trimmed] !== 'undefined') {
                    return nameIndexMap[trimmed]
                }
                const parsed = parseInt(trimmed, 10)
                if (!Number.isNaN(parsed)) {
                    return parsed
                }
            }
            return null
        })
        .filter(res => res !== null)
        .sort()
}

export function parseRender({ days: daysInput, months: monthsInput, years: yearsInput, useBooleanOr }) {
    // parse inputs from string|number|(string|number)[] to number[]
    const [days, months, years /* weeks */] = [daysInput, monthsInput, yearsInput /* weeksInput */].map(parseDateInput)

    const [checkDay, checkMonth, checkYear /* checkWeek */] = [days, months, years /* week */].map(checkDateProp)
    if (!(checkDay || checkYear || checkMonth)) {
        return { render: true, days, months, years }
    }

    const date = dayjs()
    // map all values in format [[ useTheCheck:boolean, parsedInputValues: number[], dayJsFunctionName: string]]
    const showBox = [
        [checkDay, days, 'day'],
        // [checkWeek, weeks, 'week'],
        [checkMonth, months, 'month'],
        [checkYear, years, 'year']
    ].reduce((result, [checkMe, value, dayJsFnName]) => {
        if (!checkMe) {
            return result
        }
        const checkResult = checkCurrentDate(date[dayJsFnName](), value)
        return useBooleanOr ? result || checkResult : result && checkResult
    }, !useBooleanOr) // if useBooleanOr is true, start reduce with false since we are looking for any positive result

    return { render: showBox, days, months, years }
}

export function shouldRenderOnDay(props) {
    return !!parseRender(props).render
}
