import PropTypes from 'prop-types'

import { videoAdsPropType } from '@hmn/rtl-web-core/components/VideoJsPlayer/VideoJsPlayer.component'
import { useVideoAdList } from '@hmn/rtl-web-core/context/ads/manifest.context'
import { useVideoThumbnail } from '@hmn/rtl-web-core/hooks'
import useEntityTypeDispatch from '@hmn/rtl-web-core/hooks/entity/useEntityTypeDispatch'

import { VideoContent } from '@hmn/rtl-net-ui/components/Video/components'

import { Seo } from '..'

export const config = { amp: false }

function EmbedLayout({ video, ads }) {
    useEntityTypeDispatch('video')
    const thumbnail = useVideoThumbnail({ video })

    const { id } = video

    const videoAdList = useVideoAdList()

    const videoAds = ads.videos ?? videoAdList ?? {}
    return (
        <>
            <Seo item={video} disableStructuredData />
            <VideoContent
                ads={videoAds}
                autoplay
                id={id}
                isFullscreen
                isHead
                key={video?.id}
                lead={video?.description}
                subtitle={video?.subtitle || video?.extended_attributes?.subtitle}
                thumbnail={thumbnail}
                title={video?.name}
                url={video?.extended_attributes?.stream_url || video?.original_url || video?.custom_manifest_url}
            />
        </>
    )
}

EmbedLayout.propTypes = {
    video: PropTypes.shape({
        subtitle: PropTypes.string,
        extended_attributes: PropTypes.shape({
            subtitle: PropTypes.string,
            stream_url: PropTypes.string
        }),
        name: PropTypes.string,
        original_url: PropTypes.string,
        custom_manifest_url: PropTypes.string,
        description: PropTypes.string,
        id: PropTypes.string
    }).isRequired,
    ads: videoAdsPropType
}

EmbedLayout.defaultProps = {
    ads: null
}

EmbedLayout.displayName = 'Embed'

export default EmbedLayout
