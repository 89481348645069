// @TODO: add other tags that raise errors in amp console
const ampTagMap = {
    img: 'amp-img',
    para: 'p',
    quote: 'i'
}
const ampRemoveTags = ['inlinemediaobject', 'imageobject', 'imagedata']
/**
 * Replaces amp incompatible tags with compatible ones, keeps all other tag attributes intact
 * Currently implemented for tags:
 *      img     =>  amp-img
 *      para    =>  p
 *
 * @param {string} content - contains different html tags, some of which might not be compatible with amp
 * @returns {string} parsedContent - content with replaced tags
 */
const replaceTagsForAmp = content => {
    let parsedContent = content?.toString()
    if (!parsedContent) {
        return ''
    }
    for (let i = 0; i < ampRemoveTags.length; i += 1) {
        const tag = ampRemoveTags[i]
        const regex = new RegExp(`<${tag}>.*?<\\/${tag}>`, 'gim')
        parsedContent = parsedContent?.replace(regex, ``)
    }
    const replaceAmpTags = Object.keys(ampTagMap)
    for (let i = 0; i < replaceAmpTags.length; i += 1) {
        const tag = replaceAmpTags[i]
        const compatibleTag = ampTagMap[tag]
        const regex = new RegExp(`<(/?)${tag}\\b((?:[^>"']|"[^"]*"|'[^']*')*)>`, 'gim')
        parsedContent = parsedContent?.replace(regex, `<$1${compatibleTag}$2>`)
    }
    return parsedContent
}

export default replaceTagsForAmp
